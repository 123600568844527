import { ActionContext } from 'vuex';
import { getStoreAccessors } from 'typesafe-vuex';
import { State as RootState } from '@/store/state';
import { ImplantationState } from './implantationState';
import DataIngestionModel from '@/models/implantation/componentsModels/DataIngestionModel';
import RecipesRawingModel from '@/models/implantation/componentsModels/RecipesRawingModel';
import RecipesTrustingModel from '@/models/implantation/componentsModels/RecipesTrustingModel';
import RecipesRefiningModel from '@/models/implantation/componentsModels/RecipesRefiningModel';
import ResourceModel from '@/models/implantation/componentsModels/ResourceModel';
import implantationService from '@/services/implantation/implantationService';
import { IMain } from '@/interfaces/implantation/mainInterface';

export type ImplantationContext = ActionContext<ImplantationState, RootState>;

export const implantation = {
  namespace: true,
  state: (): ImplantationState => ({
    getHeaderImplantation: '',
    getDataIngestion: new DataIngestionModel(),
    getRecipesRawing: new RecipesRawingModel(),
    getRecipesTrusting: new RecipesTrustingModel(),
    getRecipesRefining: new RecipesRefiningModel(),
    getResources: new ResourceModel(),
  }),
  getters: {
    getHeaderImplantation: (state: ImplantationState) =>
      state.getHeaderImplantation,
    getDataIngestion: (state: ImplantationState) => state.getDataIngestion,
    getRecipesRawing: (state: ImplantationState) => state.getRecipesRawing,
    getRecipesTrusting: (state: ImplantationState) => state.getRecipesTrusting,
    getRecipesRefining: (state: ImplantationState) => state.getRecipesRefining,
    getResources: (state: ImplantationState) => state.getResources,
  },
  mutations: {
    setHeaderImplantation(
      state: ImplantationState,
      headerImplantation: ImplantationState['getHeaderImplantation']
    ) {
      state.getHeaderImplantation = headerImplantation;
    },
    setDataIngestion(
      state: ImplantationState,
      dataIngestion: ImplantationState['getDataIngestion']
    ) {
      state.getDataIngestion = dataIngestion;
    },
    setRecipesRawing(
      state: ImplantationState,
      recipesRawing: ImplantationState['getRecipesRawing']
    ) {
      state.getRecipesRawing = recipesRawing;
    },
    setRecipesTrusting(
      state: ImplantationState,
      recipesTrusting: ImplantationState['getRecipesTrusting']
    ) {
      state.getRecipesTrusting = recipesTrusting;
    },
    setRecipesRefining(
      state: ImplantationState,
      recipesRefining: ImplantationState['getRecipesRefining']
    ) {
      state.getRecipesRefining = recipesRefining;
    },
    setResources(
      state: ImplantationState,
      resources: ImplantationState['getResources']
    ) {
      state.getResources = resources;
    },
  },
  actions: {
    async actionCreateMain(
      context: ImplantationContext,
      payload: any
    ): Promise<IMain | any> {
      try {
        const response = await implantationService.createMain(payload);
        return response;
      } catch (error) {
        return error;
      }
    },
    async actionSaveModifications(
      context: ImplantationContext,
      payload: any
    ): Promise<any> {
      try {
        const response = await implantationService.saveImplantation(payload);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionGetModification(
      context: ImplantationContext,
      customerId: string
    ): Promise<any> {
      try {
        const response = await implantationService.getImplantation(customerId);

        return response;
      } catch (error) {
        return error;
      }
    },
  },
};

const { dispatch, read, commit } = getStoreAccessors<
  ImplantationState,
  RootState
>('');

const { actions, mutations, getters } = implantation;

export const readHeaderImplantation = read(getters.getHeaderImplantation);
export const readDataIngestion = read(getters.getDataIngestion);
export const readRecipesRawing = read(getters.getRecipesRawing);
export const readRecipesTrusting = read(getters.getRecipesTrusting);
export const readRecipesRefining = read(getters.getRecipesRefining);
export const readResources = read(getters.getResources);

export const commitHeaderImplantation = commit(mutations.setHeaderImplantation);
export const commitDataIngestion = commit(mutations.setDataIngestion);
export const commitRecipesRawing = commit(mutations.setRecipesRawing);
export const commitRecipesTrusting = commit(mutations.setRecipesTrusting);
export const commitRecipesRefining = commit(mutations.setRecipesRefining);
export const commitResources = commit(mutations.setResources);

export const dispatchCreateMain = dispatch(actions.actionCreateMain);

export const dispatchSaveModifications = dispatch(
  actions.actionSaveModifications
);

export const dispatchGetModification = dispatch(actions.actionGetModification);
