import { ActionContext } from 'vuex';
import { getStoreAccessors } from 'typesafe-vuex';
import { State as RootState } from '@/store/state';
import {
  ICreateCustomer,
  IUpdateCustomer,
  IDeleteCustomer,
  ICustomerListData,
} from '@/interfaces/customerInteface';
import customerService from '@/services/customer/customer';
import { CustomerState } from './customerState';
import { saveLocalCustomers } from '@/utils/utils';

export type CustomersContext = ActionContext<CustomerState, RootState>;

export const customers = {
  namespace: true,
  getters: {
    customerList: (state: CustomerState) => state.customerList
  },
  mutations: {
    setCustomerList(
      state: CustomerState,
      payload: Array<ICustomerListData>
    ) {
      state.customerList = payload;
    }
  },
  actions: {
    async actionGetCustomers(
      context: CustomersContext,
      payload: { disabled: boolean }
    ) {
      const response = await customerService.getCustomers(payload.disabled);

      if (response.status === 200) {
        const customers: ICustomerListData[] = []
        response.data.forEach(customer => {
          customers.push(({
            id: customer.id,
            name: customer.name,
            has_cr_run_id: customer.has_cr_run_id,
            has_sentiment_run_id: customer.has_sentiment_run_id
          }))
        })

        const customerList = context.state.customerList ?? []
        const customersIds = customerList?.map(c => c.id)
        customerList?.push(
          ...customers.filter(c => !customersIds.includes(c.id))
            .map(({ id, name, has_cr_run_id, has_sentiment_run_id }) => (
              { id, name, has_cr_run_id, has_sentiment_run_id }
            ))
        )

        commitCustomerList(context, customerList)
        saveLocalCustomers(JSON.stringify(customerList))
      }

      return response;
    },
    async actionCreateCustomers(
      context: CustomersContext,
      payload: ICreateCustomer
    ) {
      try {
        const response = await customerService.createCustomer(payload);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionDeleteCustomers(
      context: CustomersContext,
      payload: IDeleteCustomer
    ) {
      try {
        const { id, disabled } = payload;
        const response = await customerService.deleteCustomer(id, disabled);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionUpdateCustomers(
      context: CustomersContext,
      payload: { id: number } & IUpdateCustomer
    ) {
      try {
        const customerInfo = (({ id, ...rest }) => rest)(payload);
        const response = await customerService.updateCustomer(
          payload.id,
          customerInfo
        );
        return response;
      } catch (error) {
        return error;
      }
    },
    async actionGetCustomerById(context: CustomersContext, customerId: number) {
      try {
        const response = await customerService.getCustomersById(customerId);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionUpdateCustomerFilters(
      context: CustomersContext,
      customerId: number
    ) {
      try {
        const response = await customerService.updateCustomerFilter(customerId);
        return response;
      } catch (error) {
        return error;
      }
    },
    async actionGetCustomerByCustomerDisplay(
      context: CustomersContext,
      customerDisplayId: number
    ) {
      try {
        const response = await customerService.getCustomerByCustomerDisplay(
          customerDisplayId
        );

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionGetCustomersAirflow(context: CustomersContext) {
      const response = await customerService.getCustomersAirflow();

      return response;
    },
    async actionPatchCustomersAirflow(
      context: CustomersContext,
      payload: { customer_id: string; dag_status: boolean }
    ) {
      const response = await customerService.patchCustomersAirflow(
        payload.customer_id,
        payload.dag_status
      );

      return response;
    },
  },
};

const { dispatch, read, commit } = getStoreAccessors<CustomerState, RootState>(
  ''
);

const { actions, mutations, getters } = customers;

export const dispatchGetCustomers = dispatch(actions.actionGetCustomers);
export const dispatchCreateCustomers = dispatch(actions.actionCreateCustomers);
export const dispatchDeleteCustomers = dispatch(actions.actionDeleteCustomers);
export const dispatchUpdateCustomers = dispatch(actions.actionUpdateCustomers);
export const dispatchGetCustomerById = dispatch(actions.actionGetCustomerById);
export const dispatchUpdateCustomerFilters = dispatch(
  actions.actionUpdateCustomerFilters
);
export const dispatchGetCustomersByCustomerDisplay = dispatch(
  actions.actionGetCustomerByCustomerDisplay
);
export const dispatchGetCustomersAirflow = dispatch(
  actions.actionGetCustomersAirflow
);
export const dispatchPatchCustomersAirflow = dispatch(
  actions.actionPatchCustomersAirflow
);

export const commitCustomerList = commit(mutations.setCustomerList)
export const readCustomerList = read(getters.customerList)