import {
  IDiscoveryData,
  IDiscoveryExecuteData,
  IDiscoverysUpdateData,
  IGetFilterLda,
} from '@/interfaces/discoveryInterface';
import { AxiosResponse } from 'axios';
import { apiUrl } from '../index';

const discoveryService = {
  async getDiscovery(filters: any = null) {
    let params = {};
    if (filters != null) {
      params = {
        params: {
          start_date: filters.start_date,
          end_date: filters.end_date,
          discovery_type: filters.discovery_type,
          customer_id: filters.customer_id,
        },
      };
    }
    return apiUrl
      .get('/discoveries', params)
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async getStopwords() {
    return apiUrl
      .get('/stopwords')
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async getDiscoveryById(id: number) {
    return apiUrl
      .get('/discovery', {
        params: {
          discovery_id: id,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async createDiscovery(payload: IDiscoveryData) {
    return apiUrl
      .post('/discovery', payload)
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async executeDiscovery(
    payload: IDiscoveryExecuteData
  ): Promise<AxiosResponse> {
    return apiUrl
      .post('/discovery-execute', payload, {
        params: {
          page: payload.page,
          items_per_page: payload.limit,
          sortField: payload.sortField ? payload.sortField : '_id',
          sortOrder: payload.sortOrder ? payload.sortOrder : 1,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },  
  async updateDiscovery(id: number, payload: IDiscoverysUpdateData) {
    return apiUrl
      .put(`/discovery`, payload, {
        params: {
          discovery_id: id,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async deleteDiscovery(id: number) {
    return apiUrl
      .delete(`/discovery`, {
        params: {
          discovery_id: id,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async downloadFile(customer_id: number) {
    return apiUrl
      .get('/discovery/csv', {
        params: {
          customer_id: customer_id,
        },
        responseType: 'blob',
        timeout: (60 * 1000 * 10) // 10 minutos
      })
      .then((response: any) => {

        if (response.status === 200) {
          const contentType = response.headers['content-type']
          const data = [response.data]
          const dt = new Date().toISOString().split('T')[0]
          const local_Time = new Date().toTimeString().split(' ')[0].split(':').join('-')
          const filename = 'database_' + dt + '_' + local_Time + '.csv.xz'

          this.downloadCsvFile(data, filename, contentType)

          apiUrl.delete("/discovery/csv/clear")
            .then(res => {
              if (res.status == 204)
                console.log("Operação de limpeza concluída com sucesso")
            })
            .catch(err => console.error(`Erro ao realizar a limpeza dos dados obtidos: ${err}`))
        }

        return response;
      })      
      .catch((error) => {
        if (error.code === "ECONNABORTED") {
          console.error("Request timed out")
        }
        return error.response
      });
  },
  async downloadCsvFile(data: any, filename: string, contentType: string) {
    const anchorElement = document.createElement('a')
    document.body.appendChild(anchorElement)
    anchorElement.style.display = 'none'

    const blob = new Blob(data, { type: contentType })
    const url = window.URL.createObjectURL(blob)

    anchorElement.href = url
    anchorElement.download = filename
    anchorElement.click()

    setTimeout(() => {
      window.URL.revokeObjectURL(url)
      document.body.removeChild(anchorElement)  
    }, 3000);    
  },
  async getLdaFilters(payload: IGetFilterLda): Promise<AxiosResponse> {
    return apiUrl
      .post('/discovery/filter_lda', payload)
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async executeProcessModel(payload: string): Promise<any> {
    return apiUrl
      .get('/ml-models/execute-dvc', {
        params: {
          dvc_name: payload,
        },
      })
      .then((response) => response)
      .catch((error) => error);
  },
};

export default discoveryService;
