import { TRUSTING_CONSTANT } from '@/utils/constants/implantationConstant';

export default class RecipesTrustingModel {
  public unique_identifier_columns: string[] = [];
  public channel?: string;
  public additional_columns?: string[];
  public original_date_format?: string;
  public remove_accented_characters? = true;
  public remove_html_tags? = true;
  public filter_alphanumeric_characters? = true;
  public remove_numbers? = true;
  public remove_punctuations? = true;
  public remove_numeric_words? = true;
  public remove_time_keywords? = true;
  public remove_file_extensions? = true;
  public extra_stop_words?: string[];
  public extra_columns: string[] = [];
  public min?: number = 1;
  public max?: number = 2;
  public domain_schema?: Record<string, string> = {};
  public common_words_to_remove: string[] = [];
  public export: string[] = [];

  constructor(original_date_format?: string) {
    this.original_date_format =
      original_date_format || TRUSTING_CONSTANT.original_date_format;
  }
}
