import {
  INTERLOCUTORS_CONSTANT,
  PATTERN_TIME_OF_DAY_CONSTANT,
} from '@/utils/constants/implantationConstant';

export default class RecipesRefining {
  public max_columns?: string[];
  public avg_columns?: string[];
  public remove_stop_words = { words: [] };
  public pattern_time_of_day?: string = PATTERN_TIME_OF_DAY_CONSTANT[0];
  public times?: { name: string; time: string }[];
  public message_author: string[] = [INTERLOCUTORS_CONSTANT[0]];
  public service_time = false;
  public queue_time = false;
  public bot_time = false;
  public attendant_time = false;
  public recall = {
    client_id: '',
    max_offset: 0,
    min_offset: 0,
    is_recall: false,
  };
  public messages_per_issue? = true;
  public response_time? = true;
  public attendance_duration_time = true;
  public issue_duration = true;
  public sentiment_transition?: boolean = false;
  public contact_reason?: boolean = false;
  public non_talk?: boolean = false;
  public columns_to_select: string[] = [];
}
