import { authService } from '@/services/auth/login';
import { passwordRecovery } from '@/services/auth/passwordRecovery';
import router from '@/router';
import { ActionContext } from 'vuex';
import { getStoreAccessors } from 'typesafe-vuex';
import { State as RootState } from '@/store/state';
import { LoginState } from './loginState';
import { apiUrl } from '@/services/index';
import { attachAuthHeaders, removeAuthHeaders } from '@/utils/apiUtils';
import { IUserLogin } from '@/interfaces/authInterface';
import { IChangePasswordAtLogin, IValidateRecoveryToken } from '@/interfaces/usersInterface';

export type LoginContext = ActionContext<LoginState, RootState>;

export const auth = {
  namespaced: true,
  state: (): LoginState => ({
    isLoggedIn: false,
    token: '',
    user: { id: -1, permission: -1, username: '', first_name: '' },
  }),
  getters: {
    authData: (state: LoginState) => state,
    userData: (state: LoginState) => state,
  },
  mutations: {
    setToken(state: LoginState, token: string) {
      state.token = token;
    },
    setIsLoggedIn(state: LoginState, isLoggedIn: boolean) {
      state.isLoggedIn = isLoggedIn;
    },
    setUserData(state: LoginState, userData: LoginState['user']) {
      state.user = userData;
    },
  },
  actions: {
    async login(context: LoginContext, payload: IUserLogin) {
      let token = '';

      const loginResponse = await authService.login(
        payload.username,
        payload.password
      );

      const loginData = loginResponse.data
      
      if (!loginData || loginResponse.status !== 200) {
        return { logged: false, expired: false }
      }

      if (loginData.expired) {
        return { logged: false, expired: true }
      }

      token = loginData.access_token;
      attachAuthHeaders(apiUrl, token);
      commitSetToken(context, token);
      
      return await dispatchUpdateUserData(context);
    },
    async logout() {
      removeAuthHeaders(apiUrl);
      window.localStorage.clear();
      router.currentRoute.path !== '/' && router.push('/');
    },
    async updateUserData(context: LoginContext) {
      const userDataResponse = await authService.validateToken();

      if (userDataResponse.status === 200) {
        const { id, permission, username, first_name, expired } =
          userDataResponse.data.success;

        commitSetUserData(context, { id, permission, username, first_name, expired });
        commitSetIsLoggedIn(context, true);

        return { logged: true, expired };
      }

      return { logged: false, expired: false};
    },
    async forgotPasswordEmail(context: LoginContext, email: string) {
      const passwordRecoveryData = await passwordRecovery.validateEmail(email);

      return passwordRecoveryData;
    },
    async forgotPasswordToken(
      context: LoginContext,
      payload: IValidateRecoveryToken
    ) {
      const passwordRecoveryData = await passwordRecovery.validateToken(
        payload.token,
        payload.email
      );

      return passwordRecoveryData;
    },

    async changePasswordAtLogin(context: LoginContext, payload: IChangePasswordAtLogin) {
      const changePasswordresponse = await passwordRecovery.changePasswordAtLogin(
        payload.username,
        payload.oldPassword,
        payload.newPassword
      )

      return changePasswordresponse
    }
  },
};

const { dispatch, commit, read } = getStoreAccessors<LoginState, RootState>(
  'auth'
);

const { actions, mutations, getters } = auth;

export const commitSetToken = commit(mutations.setToken);
export const commitSetUserData = commit(mutations.setUserData);
export const commitSetIsLoggedIn = commit(mutations.setIsLoggedIn);

export const dispatchLogin = dispatch(actions.login);
export const dispatchUpdateUserData = dispatch(actions.updateUserData);
export const dispatchLogout = dispatch(actions.logout);
export const dispatchForgotPasswordEmail = dispatch(actions.forgotPasswordEmail);
export const dispatchForgotPasswordToken = dispatch(actions.forgotPasswordToken);
export const dispatchChangePasswordAtLogin = dispatch(actions.changePasswordAtLogin)

export const readAuthData = read(getters.authData);
export const readUserData = read(getters.userData);
