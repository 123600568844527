import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueTheMask from 'vue-the-mask';
import authorization from './mixins/authorization';
import { addRouterGuards } from './utils/routerUtils';
import './styles/global.scss';
import { attachAuthHeaders, attachApiInterceptors } from './utils/apiUtils';
import { apiUrl } from '@/services/index';
import { dispatchLogout, readAuthData } from './store/login/loginStore';

attachApiInterceptors(apiUrl, [
  { statusCode: 401, callback: () => dispatchLogout(store) },
  {
    statusCode: 403,
    callback: () => router.push({ name: 'denied', params: { pathMatch: '' } }),
  },
]);

attachAuthHeaders(apiUrl, readAuthData(store).token || '');

addRouterGuards(router);

Vue.mixin(authorization);

Vue.config.productionTip = false;

Vue.use(VueTheMask);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
