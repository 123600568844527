import { apiUrl } from '../index';
import { ICreateMlModel, IUploadFile } from '@/interfaces/mlModelsInterface';

const mlModelsService = {
  async uploadKeyWord(payload: IUploadFile) {
    const formData = new FormData();
    formData.append('file', payload.file);
    const params = {
      niche_id: payload.niche_id,
    };
    return apiUrl
      .post('/ml-models/upload-key-words', formData, { params: params })
      .then((response) => response)
      .catch((error) => error.response);
  },
  async uploadInteractionInitial(payload: IUploadFile) {
    const formData = new FormData();
    formData.append('file', payload.file);

    return apiUrl
      .post('/ml-models/upload-interactions', formData, {
        params: {
          niche_id: payload.niche_id,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  },
  async uploadSentiment(payload: IUploadFile) {
    const formData = new FormData();
    formData.append('file', payload.file);

    return apiUrl
      .post('/ml-models/upload-sentiment-model', formData, {
        params: {
          niche_id: payload.niche_id,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  },
  async createmlModels(payload: ICreateMlModel) {
    return apiUrl
      .post('/ml-models', payload)
      .then((response) => response)
      .catch((error) => error.response);
  },
};

export default mlModelsService;
