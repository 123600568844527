import {
  ICreateUsers,
  IGetUserById,
  IUpdateUser,
  IUpdateUserInfo,
  IUpdateUserPass,
} from '@/interfaces/usersInterface';
import { AxiosResponse } from 'axios';
import { apiUrl } from '../index';

const usersService = {
  async getUsers(disabled: boolean) {
    return apiUrl
      .get('/users', {
        params: {
          disabled: disabled,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  },
  async getUserById(id: number): Promise<AxiosResponse<IGetUserById>> {
    return apiUrl
      .get('/user', {
        params: {
          user_id: id,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  },
  async getUserAvatar(id: number): Promise<AxiosResponse<Blob>> {
    return apiUrl
      .get('/user/image', {
        params: {
          user_id: id,
        },
        responseType: 'blob',
      })
      .then((response) => response)
      .catch((error) => error.response);
  },
  async createUsers(payload: ICreateUsers) {
    return apiUrl
      .post('/user', payload)
      .then((response) => response)
      .catch((error) => error.response);
  },
  async deleteUser(id: number, disabled: boolean) {
    return apiUrl
      .delete('/user', {
        params: {
          user_id: id,
          disabled: disabled,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  },
  async updateUserInfo(
    id: number,
    payload: IUpdateUserInfo
  ): Promise<AxiosResponse> {
    return apiUrl
      .put('/user/info', payload, {
        params: {
          user_id: id,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  },
  async updateUser(id: number, payload: IUpdateUser) {
    return apiUrl
      .put('/user', payload, {
        params: {
          user_id: id,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  },
  async updateAvatar(id: number, payload: File): Promise<AxiosResponse> {
    const data = new FormData();
    data.append('file', payload);

    return apiUrl
      .post('/user/image', data, {
        params: {
          user_id: id,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  },
  async getUserPermissionByUserId(id: number) {
    return apiUrl
      .get(`/permission?user_id=${id}`)
      .then((response) => response)
      .catch((error) => error.response);
  },
  async updateUserPass(payload: IUpdateUserPass) {
    return apiUrl
      .put('/user/pass', payload)
      .then((response) => response)
      .catch((error) => error.response);
  },
};

export default usersService;
