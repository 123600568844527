import {
  IGetOperationData,
  IGetOperations,
  IOperationData,
  IOperationsUpdateData,
} from '@/interfaces/operationInterface';
import { StatusCode } from '@/utils/enums/StatusCodeEnum';
import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import { apiUrl } from '../index';

const operationService = {
  async getOperation(disabled: boolean) {
    try {
      const response = await apiUrl.get('/operations', {
        params: {
          disabled: disabled,
        },
      });

      const operations: any = [];

      if (response.status === StatusCode.OK) {
        response.data.forEach((operation: IGetOperationData) => {
          if (operation) {
            const operationConst = {
              id: operation.id,
              operationName: operation.operation_name,
              operationDescription: operation.operation_description,
              createdAt: DateTime.fromISO(operation.created_at).toLocaleString(
                DateTime.DATE_SHORT
              ),
              updatedAt: operation.updated_at
                ? DateTime.fromISO(operation.updated_at).toLocaleString(
                    DateTime.DATE_SHORT
                  )
                : undefined,
            };

            operations.push(operationConst);
          }
        });
        return operations;
      } else {
        return response;
      }
    } catch (error) {
      console.error(error);
    }
  },
  async getOperationById(id: number) {
    return apiUrl
      .get('/operation', {
        params: {
          operation_id: id,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async createOperation(payload: IOperationData) {
    return apiUrl
      .post('/operations', payload)
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async updateOperation(id: number, payload: IOperationsUpdateData) {
    return apiUrl
      .put(`/operations`, payload, {
        params: {
          operation_id: id,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async deleteOperation(id: number, disabled: boolean) {
    return apiUrl
      .delete(`/operations`, {
        params: {
          operation_id: id,
          disabled: disabled,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
};

export default operationService;
