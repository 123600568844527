
import Vue from 'vue';
import NavBarComponent from './components/layout/NavBarComponent.vue';
import { readAuthData, dispatchUpdateUserData } from './store/login/loginStore';
import {
  readCustomerDisplaySelected,
  dispatchGetCustomerDisplayAvatar,
  commitCustomerDisplaySelected,
} from './store/customer/customerDisplayStore';
import {
  getCurrentCustomerDisplay,
  getCurrentCustomerName,
  getCurrentCustomerNiche,
} from '@/utils/utils';

export default Vue.extend({
  name: 'App',
  components: { NavBarComponent },
  data: () => ({
    login: false,
  }),
  watch: {
    $route() {
      this.login = this.checkIsLoginRoute();
      if (!this.login) {
        this.checkRoute();
      }
    },
  },
  async beforeMount() {
    this.login = this.checkIsLoginRoute();
    if (!this.login) {
      this.checkRoute();
    }
    if (readCustomerDisplaySelected(this.$store)?.id === undefined) {
      const customer = JSON.parse(getCurrentCustomerDisplay()!);
      const customerName = getCurrentCustomerName() ?? '';
      const customerNiche = getCurrentCustomerNiche() ?? '';
      if (this.checkCustomer(customer)) {
        await dispatchGetCustomerDisplayAvatar(this.$store, customer).then(
          (response: any) => {
            commitCustomerDisplaySelected(this.$store, {
              id: customer,
              customer_avatar: URL.createObjectURL(response.data),
              name: customerName,
              niche: parseInt(customerNiche),
            });
          }
        );
      }
    }
  },
  async beforeCreate() {
    readAuthData(this.$store).isLoggedIn &&
      (await dispatchUpdateUserData(this.$store));
  },
  methods: {
    checkIsLoginRoute() {
      const currentUrl = window.location.pathname;
      return currentUrl === '/';
    },
    checkRoute() {
      const currentUrl = window.location.pathname;
      return currentUrl;
    },
    checkCustomer(customer: number) {
      if (readAuthData(this.$store).isLoggedIn && customer === null) {
        this.$router.push({ name: 'home' });
        return false;
      }
      return true;
    },
  },
});
