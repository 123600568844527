import { apiUrl } from '../index';

export const passwordRecovery = {
  async validateEmail(email: string) {
    return apiUrl
      .get('/forgot-pass/validate-email', { params: { email: email } })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },

  async validateToken(token: number, email: string) {
    return apiUrl
      .put(
        '/forgot-pass/validate-token',
        {},
        {
          params: { token: token, email: email },
        }
      )
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },

  async changePasswordAtLogin(username: string, oldPassword: string, newPassword: string) {
    return apiUrl.put('/forgot-pass/change', {}, {
      params: {
        username,
        oldPassword,
        newPassword
      },
    })
    .then(response => response)
    .catch(error => error.response)
  }
};
