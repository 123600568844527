import { ActionContext } from 'vuex';
import { getStoreAccessors } from 'typesafe-vuex';
import { State as RootState } from '@/store/state';
import {
  IGetDiscoveriesData,
  IDiscoveryData,
  IDiscoverysUpdateData,
  IDiscoverysUpdateDataWithId,
  IDiscoveryExecuteData,
  IGetStopWordsData,
  IDiscoveryDataWithId,
  IGetFilterLda,
} from '@/interfaces/discoveryInterface';
import discoveryService from '@/services/discovery/discoveryService';
import { DiscoveryState } from './discoveryState';
import { DateTime } from 'luxon';

export type DiscoveryContext = ActionContext<DiscoveryState, RootState>;

export const discovery = {
  namespace: true,
  state: (): DiscoveryState => ({
    discoveries: [] as IGetDiscoveriesData[],
    discovery: {} as IDiscoveryData,
    stopwords: [] as IGetStopWordsData[],
  }),
  getters: {
    discoveries: (state: DiscoveryState) => state.discoveries,
    discovery: (state: DiscoveryState) => state.discovery,
    stopwords: (state: DiscoveryState) => state.stopwords,
  },
  mutations: {
    setDiscoveries(state: DiscoveryState, payload: IGetDiscoveriesData[]) {
      const discoveries = [] as unknown as IGetDiscoveriesData[];
      payload.forEach((discovery: IGetDiscoveriesData) => {
        return discoveries.push({
          id: discovery.id,
          discovery_name: discovery.discovery_name,
          customer: discovery.customer,
          customer_id: discovery.customer_id,
          discovery_type: discovery.discovery_type,
          created_by_user: discovery.created_by_user,
          queries_count: discovery.queries_count,
          created_at: DateTime.fromISO(discovery.created_at).toLocaleString(
            DateTime.DATE_SHORT
          ),
          updated_at: discovery.updated_at
            ? DateTime.fromISO(discovery.updated_at).toLocaleString(
                DateTime.DATE_SHORT
              )
            : '',
          ownedByCurrentUser: discovery.ownedByCurrentUser
        });
      });
      state.discoveries = discoveries;
    },
    setDiscovery(state: DiscoveryState, discovery: IDiscoveryData) {
      state.discovery = discovery;
    },
    setStopwords(state: DiscoveryState, payload: IGetStopWordsData[]) {
      const stopwords = [] as unknown as IGetStopWordsData[];
      payload.forEach((stopword: IGetStopWordsData) => {
        return stopwords.push({
          id: stopword.id,
          queries: stopword.queries,
          discovery_name: stopword.discovery_name,
          discovery_type: stopword.discovery_type,
        });
      });
      state.stopwords = stopwords;
    },
  },
  actions: {
    async actionGetDiscoveries(
      context: DiscoveryContext,
      payload: any
    ): Promise<IGetDiscoveriesData | any> {
      try {
        const response = await discoveryService.getDiscovery(payload);
        commitDiscovery(context, {} as IDiscoveryData);
        commitDiscoveries(context, [] as unknown as IGetDiscoveriesData[]);
        if (response.status === 200) {
          commitDiscoveries(context, response.data);
          return response;
        }
      } catch (error) {
        return error;
      }
    },
    async actionCreateDiscovery(
      context: DiscoveryContext,
      payload: IDiscoveryData
    ): Promise<IDiscoveryData | any> {
      try {
        commitDiscovery(context, {} as IDiscoveryData);
        const response = await discoveryService.createDiscovery(payload);
        return response;
      } catch (error) {
        return error;
      }
    },
    async actionExecuteDiscovery(
      context: DiscoveryContext,
      payload: IDiscoveryExecuteData
    ): Promise<IDiscoveryExecuteData | any> {
      try {
        const response = await discoveryService.executeDiscovery(payload);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionDeleteDiscovery(context: DiscoveryContext, id: number) {
      try {
        const response = await discoveryService.deleteDiscovery(id);
        return response;
      } catch (error) {
        return error;
      }
    },
    async actionGetDiscoveryById(context: DiscoveryContext, id: number) {
      try {
        const response = await discoveryService.getDiscoveryById(id);
        if (response.status === 200) {
          commitDiscovery(context, response.data);
          return response;
        }
      } catch (error) {
        return error;
      }
    },
    async actionUpdateDiscovery(
      context: DiscoveryContext,
      payload: IDiscoverysUpdateDataWithId
    ) {
      try {
        const updateDiscovery: IDiscoverysUpdateData = {
          query: payload.query,
          filters: payload.filters,
          start_date: payload.start_date,
          end_date: payload.end_date,
        };
        const response = await discoveryService.updateDiscovery(
          payload.id,
          updateDiscovery
        );

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionDownloadDiscoveriesCsv(
      context: DiscoveryContext,
      customer_id: number
    ): Promise<any> {
      try {
        const response = await discoveryService.downloadFile(customer_id);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionGetStopwords(
      context: DiscoveryContext
    ): Promise<IDiscoveryDataWithId | any> {
      try {
        const response = await discoveryService.getStopwords();
        return response;
      } catch (error) {
        return error;
      }
    },
    async actionGetFiltersLda(
      context: DiscoveryContext,
      payload: IGetFilterLda
    ): Promise<IGetFilterLda | any> {
      try {
        const response = await discoveryService.getLdaFilters(payload);
        return response;
      } catch (error) {
        return error;
      }
    },
    async actionExecuteProcessModel(
      context: DiscoveryContext,
      payload: string
    ): Promise<string | any> {
      try {
        const response = await discoveryService.executeProcessModel(payload);
        return response;
      } catch (error) {
        return error;
      }
    },
  },
};

const { dispatch, read, commit } = getStoreAccessors<DiscoveryState, RootState>(
  ''
);

const { actions } = discovery;

export const dispatchGetDiscoveries = dispatch(actions.actionGetDiscoveries);
export const dispatchCreateDiscovery = dispatch(actions.actionCreateDiscovery);
export const dispatchExecuteDiscovery = dispatch(
  actions.actionExecuteDiscovery
);
export const dispatchDeleteDiscovery = dispatch(actions.actionDeleteDiscovery);
export const dispatchUpdateDiscovery = dispatch(actions.actionUpdateDiscovery);
export const dispatchGetDiscoveryById = dispatch(
  actions.actionGetDiscoveryById
);
export const dispatchDownloadDiscoveries = dispatch(
  actions.actionDownloadDiscoveriesCsv
);

export const dispatchGetStopwords = dispatch(actions.actionGetStopwords);
export const dispatchGetFiltersLda = dispatch(actions.actionGetFiltersLda);

export const dispatchExecuteProcessModel = dispatch(
  actions.actionExecuteProcessModel
);

const { mutations } = discovery;
export const commitDiscoveries = commit(mutations.setDiscoveries);
export const commitDiscovery = commit(mutations.setDiscovery);

const { getters } = discovery;
export const readDiscoveries = read(getters.discoveries);
export const readDiscovery = read(getters.discovery);
