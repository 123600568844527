import { ActionContext } from 'vuex';
import { getStoreAccessors } from 'typesafe-vuex';
import { State as RootState } from '@/store/state';
import feelingService from '@/services/feeling/feelingService';
import { FeelingState } from './feelingState';
import { DateTime } from 'luxon';
import {
  IFeelingAccuracy,
  IFeelingData,
  IFellingCalibrationStatusData,
  IGetFeelingsData,
} from '@/interfaces/feelingInterface';

export type FeelingContext = ActionContext<FeelingState, RootState>;

export const feeling = {
  namespace: true,
  state: (): FeelingState => ({
    feelings: [] as IGetFeelingsData[],
  }),
  getters: {
    feelings: (state: FeelingState) => state.feelings,
  },
  mutations: {
    setFeelings(state: FeelingState, payload: IGetFeelingsData[]) {
      const feelings = [] as unknown as IGetFeelingsData[];
      payload.forEach((feeling: IGetFeelingsData) => {
        return feelings.push({
          id: feeling.id,
          feeling: feeling.feeling,
          description: feeling.description,
          created_by_user: feeling.created_by_user,
          created_at: DateTime.fromISO(feeling.created_at).toLocaleString(
            DateTime.DATE_SHORT
          ),
        });
      });
      state.feelings = feelings;
    },
  },
  actions: {
    async actionGetFeelings(
      context: FeelingContext,
      filter: any
    ): Promise<IGetFeelingsData | any> {
      try {
        const response = await feelingService.getFeelings(filter);
        commitFeelings(context, [] as unknown as IGetFeelingsData[]);
        if (response.status === 200) {
          commitFeelings(context, response.data);
          return response;
        }
      } catch (error) {
        return error;
      }
    },
    async actionCreateFeeling(
      context: FeelingContext,
      payload: IFeelingData
    ): Promise<IFeelingData | any> {
      try {
        const response = await feelingService.createFeeling(payload);
        return response;
      } catch (error) {
        return error;
      }
    },
    async actionGetFeelingsAccuracy(
      context: FeelingContext,
      payload: IFeelingAccuracy
    ): Promise<any> {
      try {
        const response = await feelingService.getFeelingAccuracy(payload);
        return response;
      } catch (error) {
        return error;
      }
    },
    async actionUpdateFeeling(context: FeelingContext, payload: any) {
      try {
        const response = await feelingService.updateFeeling(
          payload.update,
          payload.customer_id,
          payload.niche_id
        );

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionGetFellingCalibrationStatus(
      context: FeelingContext,
      payload: any
    ): Promise<IFellingCalibrationStatusData | any> {
      try {
        const response = await feelingService.getFellingCalibrationStatus(
          payload
        );

        return response;
      } catch (error) {
        return error;
      }
    },
  },
};

const { dispatch, read, commit } = getStoreAccessors<FeelingState, RootState>(
  ''
);

const { actions } = feeling;

export const dispatchGetFeelings = dispatch(actions.actionGetFeelings);
export const dispatchGetFeelingsAccuracy = dispatch(actions.actionGetFeelingsAccuracy);
export const dispatchCreateFeeling = dispatch(actions.actionCreateFeeling);
export const dispatchUpdateFeeling = dispatch(actions.actionUpdateFeeling);
export const dispatchGetFellingCalibrationStatus = dispatch(
  actions.actionGetFellingCalibrationStatus
);

const { mutations } = feeling;
export const commitFeelings = commit(mutations.setFeelings);

const { getters } = feeling;
export const readFeelings = read(getters.feelings);
