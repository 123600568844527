import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import { auth } from './login/loginStore';
import { customers } from './customer/customerStore';
import { customersDisplay } from './customer/customerDisplayStore';
import { users } from './users/usersStore';
import { niche } from './niche/nicheStore';
import { chanell } from './chanell/chanellStore';
import { navbar } from './layout/navbar/navbarStore';
import { State } from './state';
import { operation } from './operation/operationStore';
import { filters } from './filters/filtersStore';
import { discovery } from './discovery/discoveryStore';
import { contactReason } from './contactReason/contactReasonStore';
import { dashboard } from './dashboard/dashboardStore';
import { calibrationStatus } from './contactReason/calibrationStatus/calibrationStatusStore';
import { contactReasonScale } from './contactReason/contactReasonScale/contactReasonScaleStore';
import { mlModels } from './mlModels/mlModelsStore';
import { feeling } from './feeling/feelingStore';
import { implantation } from './implantation/implantationStore';
import { kpiStore } from './kpi/KpiStore';
import { recipesManagement } from './recipesManagement/recipesManagementStore';
import { version } from './version/versionStore';

Vue.use(Vuex);

const vueLocalStorage = new VuexPersistence({
  key: 'vuex',
  storage: window.localStorage,
  reducer: (state: State) => ({
    auth: state.auth,
  }),
});

const store = new Vuex.Store<State>({
  modules: {
    auth,
    customers,
    users,
    navbar,
    niche,
    operation,
    chanell,
    filters,
    discovery,
    contactReason,
    dashboard,
    mlModels,
    calibrationStatus,
    feeling,
    implantation,
    customersDisplay,
    contactReasonScale,
    kpiCalibration: kpiStore,
    recipesManagement,
    version,
  },
  plugins: [vueLocalStorage.plugin],
});
export default store;

const initialStateCopy = JSON.parse(JSON.stringify(store.state));

export function resetState() {
  store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)));
}
