import { ActionContext } from 'vuex';
import { getStoreAccessors } from 'typesafe-vuex';
import { State as RootState } from '@/store/state';
import contactReasonService from '@/services/contactReason/contactReasonScale';
import { ContactReasonScaleState } from './contactReasonScaleState';
import {
  IGetContactReasonData,
  IContactReasonScaleData,
} from '@/interfaces/contactReasonScaleInterface';

export type ContactResasonContext = ActionContext<
  ContactReasonScaleState,
  RootState
>;

export const contactReasonScale = {
  namespace: true,
  getters: {
    rowSelected: (state: ContactReasonScaleState) => state.getrowSelected,
  },
  mutations: {
    setrowSelected(state: ContactReasonScaleState, rowGroups: []) {
      state.getrowSelected = rowGroups;
    },
  },
  actions: {
    async actionQueryBuilder(
      context: ContactResasonContext,
      payload: IContactReasonScaleData
    ): Promise<IGetContactReasonData | any> {
      try {
        const response = await contactReasonService.makeQuery(payload);

        return response;
      } catch (error) {
        return error;
      }
    },
  },
};

const { dispatch, read, commit } = getStoreAccessors<
  ContactReasonScaleState,
  RootState
>('');

const { actions } = contactReasonScale;

export const dispatchMakeQuery = dispatch(actions.actionQueryBuilder);

const { mutations } = contactReasonScale;

export const commitRowSelected = commit(mutations.setrowSelected);

const { getters } = contactReasonScale;

export const readRowSelected = read(getters.rowSelected);
