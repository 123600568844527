import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { Permission } from '@/interfaces/usersInterface';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/LoginPage.vue'),
  },
  {
    path: '/home',
    name: 'home',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/HomePage.vue'),
  },
  {
    path: '/calibration-management',
    name: 'Gestão de Calibração',
    component: () => import('../views/CalibrationManagement.vue'),
  },
  {
    path: '/users',
    name: 'users',
    component: () =>
      import(/* webpackChunkName: "users" */ '../views/settings/UsersPage.vue'),
    meta: {
      allowFor: [Permission.admin],
    },
  },
  {
    path: '/customers',
    name: 'customer',
    component: () =>
      import(
        /* webpackChunkName: "customers" */ '../views/settings/CustomerRegistrationPage.vue'
      ),
    meta: {
      allowFor: [Permission.admin],
    },
  },
  {
    path: '/implantation',
    name: 'implantation',
    component: () =>
      import(
        /* webpackChunkName: "implantation" */ '../views/settings/ImplantationPage.vue'
      ),
  },
  {
    path: '/niches',
    name: 'niches',
    component: () =>
      import(
        /* webpackChunkName: "niches" */ '../views/settings/NichesPage.vue'
      ),
    meta: {
      allowFor: [Permission.admin],
    },
  },
  {
    path: '/operations',
    name: 'operations',
    component: () =>
      import(
        /* webpackChunkName: "operations" */ '../views/settings/OperationsPage.vue'
      ),
    meta: {
      allowFor: [Permission.admin],
    },
  },
  {
    path: '/channels',
    name: 'channels',
    component: () =>
      import(
        /* webpackChunkName: "channels" */ '../views/settings/ChanellsPage.vue'
      ),
    meta: {
      allowFor: [Permission.admin],
    },
  },
  {
    path: '/machine-learning',
    name: 'machine-learning',
    component: () =>
      import(
        /* webpackChunkName: "machine-learning" */ '../views/settings/MachineLearningPage.vue'
      ),
    meta: {
      allowFor: [Permission.admin],
    },
  },
  {
    path: '/recipes-management',
    name: 'recipes-management',
    component: () =>
      import(
        /* webpackChunkName: "users" */ '../views/recipes/RecipesManagementPage.vue'
      ),
    meta: {
      allowFor: [Permission.admin],
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ '../views/discovery/DashboardClientPage.vue'
      ),
  },
  {
    path: '/discovery',
    name: 'Descobertas',
    component: () =>
      import(
        /* webpackChunkName: "discovery" */ '../views/discovery/DiscoveryPage.vue'
      ),
  },
  {
    path: '/discovery-options',
    name: 'Criar Descobertas',
    component: () =>
      import(
        /* webpackChunkName: "discovery-options" */ '../views/discovery/DiscoveryCreateOptionsPage.vue'
      ),
  },
  {
    path: '/discovery-create',
    name: 'Nova Descoberta',
    component: () =>
      import(
        /* webpackChunkName: "discovery-create" */ '../views/discovery/CreateDiscoveryPage.vue'
      ),
  },
  {
    path: '/discovery-update/:id',
    name: 'Editar Descoberta',
    component: () =>
      import(
        /* webpackChunkName: "discovery-update" */ '../views/discovery/CreateDiscoveryPage.vue'
      ),
  },
  {
    path: '/contact-reason/create',
    name: 'create',
    component: () =>
      import(
        /* webpackChunkName: "create'" */ '../views/calibration/contactReason/ContactReasonPage.vue'
      ),
  },
  {
    path: '/contact-reason/accuracy',
    name: 'accuracy',
    component: () =>
      import(
        /* webpackChunkName: "accuracy'" */ '../views/calibration/contactReason/AccuracyPage.vue'
      ),
  },
  {
    path: '/contact-reason/scale',
    name: 'Scale',
    component: () =>
      import(
        /* webpackChunkName: "scale'" */ '../views/calibration/contactReason/ContactReasonScalePage.vue'
      ),
  },
  {
    path: '/contact-reason/calibration-status',
    name: 'calibration-status',
    component: () =>
      import('../views/calibration/contactReason/CalibrationStatusPage.vue'),
  },
  {
    path: '/feeling/scale',
    name: 'feeling_scale',
    component: () =>
      import('../views/calibration/feeling/FeelingScalePage.vue'),
  },
  // {
  //   path: '/feeling/create',
  //   name: 'feeling_create',
  //   component: () => import('../views/calibration/feeling/FeelingPage.vue'),
  // },
  {
    path: '/feeling/felling-calibration-status',
    name: 'felling-calibration-status',
    component: () =>
      import('../views/calibration/feeling/FellingCalibrationStatusPage.vue'),
  },
  {
    path: '/feeling/accuracy',
    name: 'accuracy-feeling',
    component: () =>
      import(
        /* webpackChunkName: "accuracy-feeling'" */ '../views/calibration/feeling/AccuracyFeelingPage.vue'
      ),
  },
  {
    path: '/kpi/calibration',
    name: 'kpi-calibration',
    component: () => import('../views/calibration/kpi/KPICalibrationPage.vue'),
  },
  {
    path: '/kpi/recalculations',
    name: 'kpi-recalculations',
    component: () => import('../views/calibration/kpi/KPIRecalculationPage.vue'),
  },
  {
    path: '/settings',
    name: 'Configurações',
    redirect: { name: 'profileSettings' },
    component: () => import('../views/settings/SettingsPage.vue'),
    children: [
      {
        path: 'password',
        name: 'passwordSettings',
        component: () =>
          import('../components/UserSettings/Password/PasswordTab.vue'),
      },
      {
        path: 'profile',
        name: 'profileSettings',
        component: () =>
          import('../components/UserSettings/Profile/ProfileTab.vue'),
      },
    ],
  },

  {
    path: '*',
    name: 'notFound',
    component: () => import('../views/errors/NotFound/NotFoundPage.vue'),
  },
  {
    path: '*',
    name: 'denied',
    component: () =>
      import('../views/errors/AccessDenied/AccessDeniedPage.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
