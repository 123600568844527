import { ActionContext } from 'vuex';
import { State as RootState } from '@/store/state';

import calibrationStatusService from '@/services/contactReason/calibrationStatusService';
import { CalibrationStatusState } from './calibrationStatusState';
import { ICalibrationStatusData } from '@/interfaces/calibrationStatusInterface';
import { getStoreAccessors } from 'typesafe-vuex';

export type CalibrationStatusContext = ActionContext<
  CalibrationStatusState,
  RootState
>;

export const calibrationStatus = {
  namespace: true,
  actions: {
    async actionGetCalibrationStatus(
      context: CalibrationStatusContext,
      payload: any
    ): Promise<ICalibrationStatusData | any> {
      try {
        const response = await calibrationStatusService.getCalibrationStatus(
          payload
        );

        return response;
      } catch (error) {
        return error;
      }
    },
  },
};

const { dispatch, read, commit } = getStoreAccessors<
  CalibrationStatusState,
  RootState
>('');

const { actions } = calibrationStatus;

export const dispatchGetCalibrationStatus = dispatch(
  actions.actionGetCalibrationStatus
);
