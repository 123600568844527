
import Vue from 'vue';
import { readCustomerDisplaySelected } from '@/store/customer/customerDisplayStore';
import { readAvatarUrl } from '@/store/layout/navbar/navbarStore';
import { resetState } from '@/store';
import { dispatchLogout } from '@/store/login/loginStore';
import { Permission } from '@/interfaces/usersInterface';
import { readAuthData } from '@/store/login/loginStore';
import { dispatchGetUserAvatar } from '@/store/users/usersStore';
import { commitAvatarUrl } from '@/store/layout/navbar/navbarStore';
import { readUserData } from '@/store/login/loginStore';
import { dispatchGetLatest } from '@/store/version/versionStore';

export default Vue.extend({
  name: 'NavBarComponent',
  data: () => ({
    loadingAvatar: false,
    drawer: null,
    image: '',
    currentAvatarUrl: null as null | string,
    customerCreated: true,
    group: null,
    itemsHome: [{ title: 'Home', link: '/home' }],
    itemsWithDropDown: [
      {
        title: 'Galeria de Descobertas',
        icon: 'mdi-cog',
        active: true,
        items: [
          { title: 'Dashboard', link: '/dashboard' },
          { title: 'Descobertas Realizadas', link: '/discovery' },
          { title: 'Criar Descobertas', link: '/discovery-options' },
        ],
      },
      {
        title: 'Galeria de Calibrações',
        hasSubGroup: true,
        active: false,
        subGroups: [
          {
            title: 'Motivos de Contato',
            items: [
              { subtitle: 'Acurácia', link: '/contact-reason/accuracy' },
              {
                subtitle: 'Calibração - Status',
                link: '/contact-reason/calibration-status',
              },
              { subtitle: 'Calibração - Massa', link: '/contact-reason/scale' },
              { subtitle: 'Cadastro', link: '/contact-reason/create' },
            ],
          },
          {
            title: 'Sentimento',
            items: [
              { subtitle: 'Acurácia', link: '/feeling/accuracy' },
              {
                subtitle: 'Calibração - Status',
                link: '/feeling/felling-calibration-status',
              },
              { subtitle: 'Calibração - Massa', link: '/feeling/scale' },
              // { subtitle: 'Cadastro', link: '/feeling/create' },
            ],
          },
          {
            title: ' KPI',
            items: [
              { subtitle: 'Calibração', link: '/kpi/calibration' },
              { subtitle: 'Recálculo', link: '/kpi/recalculations' }
            ],
          },
        ],
      },
    ],
    onlyItemsAdmin: [
      { title: 'Gestão de Calibração', link: '/calibration-management' },
    ],
    itemsAdmin: [
      {
        title: 'Configurações',
        active: false,
        isSetting: true,
        items: [
          { title: 'Usuários', link: '/users' },
          { title: 'Operações', link: '/operations' },
          { title: 'Nichos', link: '/niches' },
          { title: 'Canais', link: '/channels' },
          { title: 'Clientes', link: '/customers' },
          { title: 'Implantação', link: '/implantation' },
          { title: 'Criação de Modelos ML', link: '/machine-learning' },
          { title: 'Gestão de Receitas', link: '/recipes-management' },
        ],
      },
    ],
    userItems: [{ title: 'Configurações', icon: 'mdi-cog', link: '/settings' }],
    appVersion: ''
  }),  
  computed: {
    avatarUrl() {
      var customer = readCustomerDisplaySelected(this.$store);
      return customer?.customer_avatar;
    },
    userImageUrl() {
      return readAvatarUrl(this.$store);
    },

    customerSelected() {
      return readCustomerDisplaySelected(this.$store)?.id;
    },

    customerSelectedName() {
      return readCustomerDisplaySelected(this.$store)?.name;
    },

    hasAdmin() {
      return readAuthData(this.$store)?.user.permission === Permission.admin
        ? true
        : false;
    },
  },
  async created() {
    const latestVersion = await dispatchGetLatest(this.$store)
    this.appVersion = latestVersion.number
  },
  async beforeMount() {
    await this.loadAvatar(readUserData(this.$store).user.id);
  },
  methods: {
    logout() {
      resetState();
      dispatchLogout(this.$store);
    },
    async loadAvatar(userId: number) {
      this.loadingAvatar = true;
      const response = await dispatchGetUserAvatar(this.$store, userId);

      if (response.status === 200) {
        const avatar = URL.createObjectURL(new Blob([response.data]));
        commitAvatarUrl(this.$store, avatar);
        this.currentAvatarUrl = avatar;
      }
      this.loadingAvatar = false;
    },
  },
});
