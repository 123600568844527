import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  iconfont: 'md',
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#757575',
        secondary: '#FF9300',
        colorButton: '#DDE4EB',
        colorTextButton: '#001737',
        colorSwitch: '#FFA933',
      },
    },
  },
});
