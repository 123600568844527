import { ActionContext } from 'vuex';
import { getStoreAccessors } from 'typesafe-vuex';
import { State as RootState } from '@/store/state';
import {
  IDeleteOperation,
  IGetOperationData,
  IOperationData,
  IOperationsUpdateData,
  IOperationsUpdateDataWithId,
} from '@/interfaces/operationInterface';
import operationService from '@/services/operations/operationsService';
import { OperationState } from './operationState';

export type OperationContext = ActionContext<OperationState, RootState>;

export const operation = {
  namespace: true,
  actions: {
    async actionGetOperations(
      context: OperationContext,
      payload: { disabled: boolean }
    ): Promise<IGetOperationData | any> {
      try {
        const response = await operationService.getOperation(payload.disabled);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionCreateOperation(
      context: OperationContext,
      payload: IOperationData
    ): Promise<IOperationData | any> {
      try {
        const response = await operationService.createOperation(payload);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionDeleteOperation(
      context: OperationContext,
      payload: IDeleteOperation
    ) {
      try {
        const { id, disabled } = payload;
        const response = await operationService.deleteOperation(id, disabled);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionGetOperationById(context: OperationContext, id: number) {
      try {
        const response = await operationService.getOperationById(id);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionUpdateOperation(
      context: OperationContext,
      payload: IOperationsUpdateDataWithId
    ) {
      try {
        const updateOperation: IOperationsUpdateData = {
          operation_description: payload.operation_description,
        };
        const response = await operationService.updateOperation(
          payload.id,
          updateOperation
        );

        return response;
      } catch (error) {
        return error;
      }
    },
  },
};

const { dispatch, read, commit } = getStoreAccessors<OperationState, RootState>(
  ''
);

const { actions } = operation;

export const dispatchGetOperations = dispatch(actions.actionGetOperations);
export const dispatchCreateOperation = dispatch(actions.actionCreateOperation);
export const dispatchDeleteOperation = dispatch(actions.actionDeleteOperation);
export const dispatchUpdateOperation = dispatch(actions.actionUpdateOperation);
export const dispatchGetOperationById = dispatch(
  actions.actionGetOperationById
);
