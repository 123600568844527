import { Permission, PermissionName } from '@/interfaces/usersInterface';
import store from '@/store';
import { readAuthData } from '@/store/login/loginStore';

export default {
  data() {
    return {
      Permission,
      PermissionName,
    };
  },
  methods: {
    has: (permission: Permission) =>
      permission === readAuthData(store).user.permission,
  },
};
