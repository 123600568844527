import { ActionContext } from 'vuex';
import { NavBarState } from './navbarState';
import { State as RootState } from '@/store/state';
import { getStoreAccessors } from 'typesafe-vuex';

export type NavbarContext = ActionContext<NavBarState, RootState>;

export const navbar = {
  namespaced: true,
  state: (): NavBarState => ({ avatarUrl: null }),
  getters: {
    getAvatarUrl(state: NavBarState) {
      return state.avatarUrl;
    },
  },
  mutations: {
    setAvatarUrl(state: NavBarState, avatarUrl: string) {
      state.avatarUrl = avatarUrl;
    },
  },
};

const { read, commit } = getStoreAccessors<NavBarState, RootState>('navbar');
const { mutations, getters } = navbar;

export const readAvatarUrl = read(getters.getAvatarUrl);
export const commitAvatarUrl = commit(mutations.setAvatarUrl);
