import { apiUrl } from '../index';

const filtersService = {
  async getFilters(customerId: number | string) {
    return apiUrl
      .get('/deep-dive/filters', {
        params: {
          customer_id: customerId,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  },
};

export default filtersService;
