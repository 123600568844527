import { ActionContext } from 'vuex';
import { getStoreAccessors } from 'typesafe-vuex';
import { State as RootState } from '@/store/state';
import { ICreateMlModel, IUploadFile } from '@/interfaces/mlModelsInterface';
import mlModelsService from '@/services/mlModels/mlModels';
import { MlModelsState } from './mlModelsState';

export type MlModelsContext = ActionContext<MlModelsState, RootState>;
export const mlModels = {
  namespace: true,
  actions: {
    async actionCreateMlModel(
      context: MlModelsContext,
      payload: ICreateMlModel
    ) {
      try {
        const response = await mlModelsService.createmlModels(payload);

        return response;
      } catch (error) {
        return error;
      }
    },
    async uploadKeyWordFile(context: MlModelsContext, payload: IUploadFile) {
      try {
        const response = await mlModelsService.uploadKeyWord(payload);

        return response;
      } catch (error) {
        return error;
      }
    },
    async uploadInteractionInitial(
      context: MlModelsContext,
      payload: IUploadFile
    ) {
      try {
        const response = await mlModelsService.uploadInteractionInitial(
          payload
        );

        return response;
      } catch (error) {
        return error;
      }
    },
    async uploadSentiment(context: MlModelsContext, payload: IUploadFile) {
      try {
        const response = await mlModelsService.uploadSentiment(payload);

        return response;
      } catch (error) {
        return error;
      }
    },
  },
};

const { dispatch, read, commit } = getStoreAccessors<MlModelsState, RootState>(
  ''
);

const { actions } = mlModels;

export const dispatchCreateMlModel = dispatch(actions.actionCreateMlModel);
export const dispatchUploadKeyWordFile = dispatch(actions.uploadKeyWordFile);
export const dispatchUploadInteractionInitial = dispatch(
  actions.uploadInteractionInitial
);
export const dispatchUploadSentiment = dispatch(actions.uploadSentiment);
