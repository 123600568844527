import {
  IChanellData,
  IChanellsUpdateData,
  IGetChanellData,
} from '@/interfaces/chanellInterface';
import { StatusCode } from '@/utils/enums/StatusCodeEnum';
import { DateTime } from 'luxon';
import { apiUrl } from '../index';

const chanellService = {
  async getChanell(disabled: boolean) {
    try {
      const response = await apiUrl.get('/channels', {
        params: {
          disabled: disabled,
        },
      });

      const channels: any = [];

      if (response.status === StatusCode.OK) {
        response.data.forEach((chanell: IGetChanellData) => {
          if (chanell) {
            const channel = {
              id: chanell.id,
              name: chanell.channel_name,
              explanation: chanell.channel_description,
              channelCustomer: chanell.customer,
              type: chanell.channel_type,
              selectChanell: chanell.channel_type,
              createdAt: DateTime.fromISO(chanell.created_at).toLocaleString(
                DateTime.DATE_SHORT
              ),
              updatedAt: chanell.updated_at
                ? DateTime.fromISO(chanell.updated_at).toLocaleString(
                    DateTime.DATE_SHORT
                  )
                : undefined,
            };

            channels.push(channel);
          }
        });

        return channels;
      } else {
        return response;
      }
    } catch (error) {
      console.error(error);
    }
  },
  async getChanellById(id: number) {
    return apiUrl
      .get('/channel', {
        params: {
          channel_id: id,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async createChanell(payload: IChanellData) {
    return apiUrl
      .post('/channels', payload)
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async updateChanell(id: number, payload: IChanellsUpdateData) {
    return apiUrl
      .put(`/channels`, payload, {
        params: {
          channel_id: id,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async deleteChanell(id: number, disabled: boolean) {
    return apiUrl
      .delete(`/channels`, {
        params: {
          channel_id: id,
          disabled: disabled,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
};

export default chanellService;
