import {
  ICreateCustomer,
  IGetCustomers,
  IUpdateCustomer,
} from '@/interfaces/customerInteface';
import { AxiosResponse } from 'axios';
import { apiUrl } from '../index';

const customersService = {
  async getCustomers(
    disabled: boolean
  ): Promise<AxiosResponse<IGetCustomers[]>> {
    return apiUrl
      .get('/customers', {
        params: {
          disabled: disabled,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async getCustomersById(customerId: number) {
    return apiUrl
      .get(`/customer`, {
        params: {
          customer_id: customerId,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async createCustomer(payload: ICreateCustomer) {
    return apiUrl
      .post('/customer', payload)
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async deleteCustomer(id: number, disabled: boolean) {
    return apiUrl
      .delete(`/customer`, {
        params: {
          customer_id: id,
          disabled: disabled,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async updateCustomer(customerId: number, payload: IUpdateCustomer) {
    return apiUrl
      .put(`/customer`, payload, {
        params: {
          customer_id: customerId,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async updateCustomerFilter(customerId: number) {
    return apiUrl
      .put(
        '/customer/filter',
        {},
        {
          params: {
            customer_id: customerId,
          },
        }
      )
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async getCustomerByCustomerDisplay(customerDisplayId: number) {
    return apiUrl
      .get('/customer/customer-display', {
        params: {
          customer_display_id: customerDisplayId,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async getCustomersAirflow(): Promise<any> {
    return apiUrl
      .get('/customer/airflow')
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async patchCustomersAirflow(
    customer_id: string,
    dag_status: boolean
  ): Promise<any> {
    return apiUrl
      .patch(
        '/customer/airflow',
        {},
        {
          params: {
            customer_id: customer_id,
            dag_status: dag_status,
          },
        }
      )
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
};

export default customersService;
