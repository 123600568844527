import {
  IContactReasonDownload,
  IContactReasonsCalibration,
  IContactReasonsResults,
  ICreateContactReason,
  ICreateCategory,
  ICreateSubContactReason,
} from '@/interfaces/contactReasonInterface';
import { apiUrl } from '../index';
import { AxiosResponse } from 'axios';
import { StatusCode } from '@/utils/enums/StatusCodeEnum';
import { DateTime } from 'luxon';

const contactReasonService = {
  async getCategory(niche: number) {
    try {
      const response = await apiUrl.get('/contact-reason/category', {
        params: {
          niche: niche,
        },
      });

      if (response.status === StatusCode.OK) {
        return response.data
          .map((item: any) => {
            return {
              id: item.id,
              category: item.category,
              created_by_user: item.created_by_user,
              description: item.description,
              created_at: DateTime.fromISO(item.created_at).toLocaleString(
                DateTime.DATE_SHORT
              ),
              updated_at: item.updated_at
                ? DateTime.fromISO(item.updated_at).toLocaleString(
                    DateTime.DATE_SHORT
                  )
                : '',
            };
          })
          .sort((a: any, b: any) =>
            a.category.toLowerCase() < b.category.toLowerCase() ? -1 : 1
          );
      }
    } catch (error) {
      console.error(error);
    }
  },
  async getContactReason(niche: number) {
    try {
      const response = await apiUrl.get('/contact-reason/new', {
        params: {
          niche: niche,
        },
      });

      if (response.status === StatusCode.OK) {
        return response.data
          .map((contact_reason: any) => {
            return {
              id: contact_reason.id,
              category: contact_reason.category_name,
              contact_reason: contact_reason.contact_reason,
              description: contact_reason.description,
              created_by_user: contact_reason.created_by_user,
              created_at: DateTime.fromISO(
                contact_reason.created_at
              ).toLocaleString(DateTime.DATE_SHORT),
              updated_at: contact_reason.updated_at
                ? DateTime.fromISO(contact_reason.updated_at).toLocaleString(
                    DateTime.DATE_SHORT
                  )
                : '',
            };
          })
          .sort((a: any, b: any) =>
            a.contact_reason.toLowerCase() < b.contact_reason.toLowerCase()
              ? -1
              : 1
          );
      }
    } catch (error) {
      console.error(error);
    }
  },
  async getSubCategory(niche: number) {
    try {
      const response = await apiUrl.get('/contact-reason/sub', {
        params: {
          niche: niche,
        },
      });

      if (response.status === StatusCode.OK) {
        return response.data
          .map((item: any) => {
            return {
              id: item.id,
              category: item.category_name,
              contact_reason: item.contact_reason_name,
              sub_contact_reason: item.sub_contact_reason,
              created_by_user: item.created_by_user,
              description: item.description,
              created_at: DateTime.fromISO(item.created_at).toLocaleString(
                DateTime.DATE_SHORT
              ),
              updated_at: item.updated_at
                ? DateTime.fromISO(item.updated_at).toLocaleString(
                    DateTime.DATE_SHORT
                  )
                : '',
            };
          })
          .sort((a: any, b: any) =>
            a.sub_contact_reason.toLowerCase() <
            b.sub_contact_reason.toLowerCase()
              ? -1
              : 1
          );
      }
    } catch (error) {
      console.error(error);
    }
  },
  async getContactReasonById(id: number) {
    return apiUrl
      .get(`/contact-reason`, {
        params: {
          contact_reason_id: id,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  },
  async createContactReason(payload: ICreateContactReason) {
    return apiUrl
      .post('/contact-reason/new', payload)
      .then((response) => response)
      .catch((error) => error.response);
  },
  async createCategory(payload: ICreateCategory) {
    return apiUrl
      .post('/contact-reason/category', payload)
      .then((response) => response)
      .catch((error) => error.response);
  },
  async createSubContactReason(payload: ICreateSubContactReason) {
    return apiUrl
      .post('/contact-reason/sub', payload)
      .then((response) => response)
      .catch((error) => error.response);
  },
  async updateContactReason(
    payload: any,
    customer_id: number,
    niche_id: number
  ) {
    return apiUrl
      .put(`/contact-reason/refined`, payload, {
        params: {
          niche_id: niche_id,
          customer_id: customer_id,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  },
  async deleteContactReason(id: number) {
    return apiUrl
      .delete(`/contact-reason`, {
        params: {
          contact_reason_id: id,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  },
  async getContactReasonResults(payload: IContactReasonsResults) {
    return apiUrl
      .get('/contact-reason/info', {
        params: {
          niche_id: payload.niche_id,
          customer_id: payload.customer_id,
          open_date: payload.open_date,
          close_date: payload.close_date,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  },
  async makeQuery(payload: IContactReasonsCalibration): Promise<AxiosResponse> {
    return apiUrl
      .post('/contact-reason/refined', payload, {
        params: {
          page: payload.page,
          items_per_page: payload.limit,
          sortField: payload.sortField,
          sortOrder: payload.sortOrder,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  },
  async downloadFile(payload: IContactReasonDownload) {
    return apiUrl
      .get('/contact-reason/csv', {
        params: {
          contact_reason_collumn: payload.contact_reason_collumn,
          contact_reason: payload.contact_reason,
          customer_id: payload.customer_id,
          query_field: payload.query_field,
          start_date: payload.start_date,
          end_date: payload.end_date,
        },
        responseType: 'blob',
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/zip' }),
          url = window.URL.createObjectURL(blob);

        window.open(url);
        return response;
      })
      .catch((error) => error.response);
  },
};

export default contactReasonService;
