import {
  ICreateCustomerDisplay,
  IGetCustomersDisplay,
  IUpdateCustomerDisplay,
} from '@/interfaces/customerDisplayInteface';
import { AxiosResponse } from 'axios';
import { apiUrl } from '../index';

const customersDisplayService = {
  async getCustomersDisplay(
    disabled: boolean
  ): Promise<AxiosResponse<IGetCustomersDisplay[]>> {
    return apiUrl
      .get('/customers-display', {
        params: {
          disabled: disabled,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async getCustomersDisplayById(customerId: number) {
    return apiUrl
      .get(`/customer-display`, {
        params: {
          customer_id: customerId,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async createCustomerDisplay(payload: ICreateCustomerDisplay) {
    return apiUrl
      .post('/customers-display', payload)
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async deleteCustomerDisplay(id: number, disabled: boolean) {
    return apiUrl
      .delete(`/customers-display`, {
        params: {
          customer_id: id,
          disabled: disabled,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async updateCustomerDisplay(customerId: number, payload: IUpdateCustomerDisplay) {
    return apiUrl
      .put(`/customers-display`, payload, {
        params: {
          customer_id: customerId,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async updateCustomerDisplayAvatar(
    id: number,
    payload: File
  ): Promise<AxiosResponse> {
    const data = new FormData();
    data.append('file', payload);

    return apiUrl
      .post('/customers-display/image', data, {
        params: {
          customer_id: id,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async getCustomerDisplayAvatar(id: number): Promise<AxiosResponse<Blob>> {
    return apiUrl
      .get('/customers-display/image', {
        params: {
          customer_id: id,
        },
        responseType: 'blob',
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
};

export default customersDisplayService;
