import VueRouter from 'vue-router';
import { readAuthData } from '@/store/login/loginStore';
import store from '@/store';
import { Permission } from '@/interfaces/usersInterface';

export const addRouterGuards = (router: VueRouter) => {
  router.beforeEach((to, from, next) => {
    if (!readAuthData(store).isLoggedIn && to.name !== 'login') {
      next({ name: 'login' });
    }

    const allowFor: Permission[] | null = to.meta?.allowFor;
    let isAllowed = false;
    if (allowFor) {
      allowFor.forEach((permission: Permission) => {
        if (readAuthData(store).user.permission === permission) {
          isAllowed = true;
        }
      });
      !isAllowed && next({ name: 'denied', params: { pathMatch: to.path } });
    }
    next();
  });
};
