import { DateTime } from 'luxon';

export const DATA_INGESTION_CONSTANT = {
  reprocessing: 30,
};

export const TRUSTING_CONSTANT = {
  original_date_format: '%d/%m/%Y %H:%M|%Y-%m-%d %H:%M:%S|%d-%m-%Y %H:%M',
};

export const DOMAIN_CONSTANT = {
  domainScheme: [
    'issue_id',
    'channel',
    'client_id',
    'client_type',
    'close_date',
    'contact_reason',
    'message_author',
    'message_content',
    'message_time',
    'original_message',
    'open_date',
  ],
};

interface ChannelsScheme {
  [key: string]: string[];
}

export const CHANNELS_SCHEME: ChannelsScheme = {
  chat: [
    'attendant_id',
    'attendant_start_time',
    'attendant_end_time',
    'bot_start_time',
    'bot_end_time',
    'message_order',
    'queue_start_time',
    'queue_end_time',
    'status',
    'tags',
    'transfered_conversation',
  ],
  social_media: ['publish_date', 'message_sentiment', 'message_order'],
  email: ['attendant_id', 'message_order', 'status'],
  ra: [
    'changed_at',
    'evaluated_at',
    'answered_at',
    'tags',
    'status',
    'name',
    'telephones',
    'city',
    'state',
    'legal_id',
    'client_complementary_info',
    'title',
    'answer',
    'score',
    'interactions',
    'evaluation_time',
    'history',
    'would_do_business_again',
    'problem_solved',
    'consumer_considerations',
  ],
  ativo: [
    'attendant_id',
    'attendant_start_time',
    'attendant_end_time',
    'client_start_time',
    'client_end_time',
    'message_order',
    'queue_start_time',
    'queue_end_time',
    'status',
    'transfered_conversation',
  ],
  facebook: ['publish_date', 'message_sentiment', 'message_order'],
  instagram: ['publish_date', 'message_sentiment', 'message_order'],
  linkedin: ['publish_date', 'message_sentiment', 'message_order'],
  receptivo: [
    'attendant_id',
    'attendant_start_time',
    'attendant_end_time',
    'client_start_time',
    'client_end_time',
    'message_order',
    'queue_start_time',
    'queue_end_time',
    'status',
    'transfered_conversation',
  ],
  twitter: ['publish_date', 'message_sentiment', 'message_order'],
  whatsapp: [
    'attendant_id',
    'attendant_start_time',
    'attendant_end_time',
    'bot_start_time',
    'bot_end_time',
    'client_start_time',
    'client_end_time',
    'message_order',
    'queue_start_time',
    'queue_end_time',
    'status',
    'tags',
    'transfered_conversation'
  ],
  voz: [
    'attendant_id', 
    'attendant_start_time', 
    'attendant_end_time', 
    'client_start_time', 
    'client_end_time', 
    'message_order', 
    'queue_start_time', 
    'queue_end_time', 
    'status', 
    'transfered_conversation'
  ],
  crm: [
    'attendant_id', 
    'status', 
    'message_order'
  ],
  pesquisa: [
    'nota', 
    'nps', 
    'csat', 
    'ces', 
    'status', 
    'message_order'
  ]
};

export const RESOURCES_CONSTANT = {
  ingestion_type: ['full', 'incremental'],
};

export const INTERLOCUTORS_CONSTANT = ['client', 'agent', 'bot'];

export const PATTERN_TIME_OF_DAY_CONSTANT = ['%H:%M:%S', '%H:%M'];

//cron POC
const nextDay = DateTime.local()
  .setZone('America/Sao_Paulo')
  .plus({ days: 1 }).day;

const month = DateTime.local().setZone('America/Sao_Paulo').month;
const year = DateTime.local().setZone('America/Sao_Paulo').year;

export const CRON_POC_NEXT_DAY =
  '0 0 8 ' + nextDay + ' ' + month + ' ? ' + year; // será executado ás 8h do dia seguinte, do mês e ano atual