import { ActionContext } from 'vuex';
import { getStoreAccessors } from 'typesafe-vuex';
import { State as RootState } from '@/store/state';
import {
  IVersionData
} from '@/interfaces/versionInterface';
import versionService from '@/services/version/versionService';
import { VersionState } from './versionState';

export type VersionContext = ActionContext<VersionState, RootState>;

export const version = {
  namespace: true,
  actions: {
    async actionGetLatest(
      context: VersionContext,
    ): Promise<IVersionData | any> {
      try {
        const response = await versionService.getLatest()

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionGetByNumber(
      context: VersionContext,
      number: string
    ): Promise<IVersionData | any> {
      try {
        const response = await versionService.getByNumber(number)

        return response;
      } catch (error) {
        return error;
      }
    }
  },
};

const { dispatch, read, commit } = getStoreAccessors<VersionState, RootState>(
  ''
);

const { actions } = version;

export const dispatchGetLatest = dispatch(actions.actionGetLatest);
export const dispatchGetByNumber = dispatch(actions.actionGetByNumber)
