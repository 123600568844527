import { ActionContext } from 'vuex';
import { getStoreAccessors } from 'typesafe-vuex';
import { State as RootState } from '@/store/state';
import {
  IGetNicheData,
  INicheData,
  INichesUpdateData,
  INichesUpdateDataWithId,
} from '@/interfaces/nicheInterface';
import nicheService from '@/services/niche/nicheService';
import { NicheState } from './nicheState';

export type NicheContext = ActionContext<NicheState, RootState>;

export const niche = {
  namespace: true,
  actions: {
    async actionGetNiches(context: NicheContext): Promise<IGetNicheData | any> {
      try {
        const response = await nicheService.getNiche();

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionCreateNiche(
      context: NicheContext,
      payload: INicheData
    ): Promise<INicheData | any> {
      try {
        const response = await nicheService.createNiche(payload);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionDeleteNiche(context: NicheContext, id: number) {
      try {
        const response = await nicheService.deleteNiche(id);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionGetNicheById(context: NicheContext, id: number) {
      try {
        const response = await nicheService.getNicheById(id);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionUpdateNiche(
      context: NicheContext,
      payload: INichesUpdateDataWithId
    ) {
      try {
        const updateNiche: INichesUpdateData = {
          niche_description: payload.niche_description,
        };
        const response = await nicheService.updateNiche(
          payload.id,
          updateNiche
        );

        return response;
      } catch (error) {
        return error;
      }
    },
  },
};

const { dispatch, read, commit } = getStoreAccessors<NicheState, RootState>('');

const { actions } = niche;

export const dispatchGetNiches = dispatch(actions.actionGetNiches);
export const dispatchCreateNiche = dispatch(actions.actionCreateNiche);
export const dispatchDeleteNiche = dispatch(actions.actionDeleteNiche);
export const dispatchUpdateNiche = dispatch(actions.actionUpdateNiche);
export const dispatchGetNicheById = dispatch(actions.actionGetNicheById);
