import { ActionContext } from 'vuex';
import { getStoreAccessors } from 'typesafe-vuex';
import { State as RootState } from '@/store/state';
import {
  ICreateCustomerDisplay,
  IUpdateCustomerDisplay,
  IGetCustomersDisplay,
  IGetCustomerDisplayWithAvatar,
  IDeleteCustomerDisplay,
} from '@/interfaces/customerDisplayInteface';
import customersDisplayService from '@/services/customer/customerDisplay';
import { CustomerDisplayState } from './customerDisplayState';
import { getCustomerDisplaySelected, saveCustomerDisplaySelected } from '@/utils/utils';

export type CustomersDisplayContext = ActionContext<CustomerDisplayState, RootState>;

export const customersDisplay = {
  namespace: true,
  state: (): CustomerDisplayState => ({
    getCustomersDisplay: [] as Array<IGetCustomersDisplay>,
    selectCustomerDisplay: {} as IGetCustomerDisplayWithAvatar,
  }),
  getters: {
    getCustomersDisplay: (state: CustomerDisplayState) => state.getCustomersDisplay,
    getCustomersDisplaySelected: (state: CustomerDisplayState) => {
      const customerDisplaySelected = getCustomerDisplaySelected()
      
      return ((state.selectCustomerDisplay?.id ?? 0) > 0)
        ? state.selectCustomerDisplay 
        : JSON.parse(customerDisplaySelected ?? '{}')
    }
  },
  mutations: {
    setCustomerDisplay(state: CustomerDisplayState, customer: CustomerDisplayState['getCustomersDisplay']) {
      state.getCustomersDisplay = customer;
    },
    setCustomerDisplaySelected(
      state: CustomerDisplayState,
      customer: CustomerDisplayState['selectCustomerDisplay']
    ) {
      state.selectCustomerDisplay = customer;
      saveCustomerDisplaySelected(JSON.stringify(customer))
    },
  },
  actions: {
    async actionGetCustomersDisplay(
      context: CustomersDisplayContext,
      payload: { disabled: boolean }
    ) {
      const response = await customersDisplayService.getCustomersDisplay(payload.disabled);
      if (response.status === 200) {
        commitCustomerDisplay(context, response.data);
      }

      return response;
    },
    async actionCreateCustomersDisplay(
      context: CustomersDisplayContext,
      payload: ICreateCustomerDisplay
    ) {
      try {
        const response = await customersDisplayService.createCustomerDisplay(payload);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionDeleteCustomersDisplay(
      context: CustomersDisplayContext,
      payload: IDeleteCustomerDisplay
    ) {
      try {
        const { id, disabled } = payload;
        const response = await customersDisplayService.deleteCustomerDisplay(id, disabled);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionUpdateCustomersDisplay(
      context: CustomersDisplayContext,
      payload: { id: number } & IUpdateCustomerDisplay
    ) {
      try {
        const customerInfo = (({ id, ...rest }) => rest)(payload);
        const response = await customersDisplayService.updateCustomerDisplay(
          payload.id,
          customerInfo
        );
        return response;
      } catch (error) {
        return error;
      }
    },
    async actionGetCustomerDisplayById(context: CustomersDisplayContext, customerId: number) {
      try {
        const response = await customersDisplayService.getCustomersDisplayById(customerId);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionUpdateCustomerDisplayAvatar(
      context: CustomersDisplayContext,
      payload: { id: number; file: File }
    ) {
      const response = await customersDisplayService.updateCustomerDisplayAvatar(
        payload.id,
        payload.file
      );
      return response;
    },
    async actionGetCustomerDisplayAvatar(context: CustomersDisplayContext, id: number) {
      const response = await customersDisplayService.getCustomerDisplayAvatar(id);
      if (response.status === 200) {
        return response;
      }
      return response;
    },
  },
};

const { dispatch, read, commit } = getStoreAccessors<CustomerDisplayState, RootState>(
  ''
);

const { actions, mutations, getters } = customersDisplay;

export const commitCustomerDisplay = commit(mutations.setCustomerDisplay);
export const commitCustomerDisplaySelected = commit(mutations.setCustomerDisplaySelected);

export const dispatchGetCustomersDisplay = dispatch(actions.actionGetCustomersDisplay);
export const dispatchCreateCustomersDisplay = dispatch(actions.actionCreateCustomersDisplay);
export const dispatchDeleteCustomersDisplay = dispatch(actions.actionDeleteCustomersDisplay);
export const dispatchUpdateCustomersDisplay = dispatch(actions.actionUpdateCustomersDisplay);
export const dispatchGetCustomerDisplayById = dispatch(actions.actionGetCustomerDisplayById);
export const dispatchUpdateCustomerDisplayAvatar = dispatch(
  actions.actionUpdateCustomerDisplayAvatar
);
export const dispatchGetCustomerDisplayAvatar = dispatch(
  actions.actionGetCustomerDisplayAvatar
);

export const readCustomerDisplayData = read(getters.getCustomersDisplay);
export const readCustomerDisplaySelected = read(getters.getCustomersDisplaySelected);
