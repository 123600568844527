import { apiUrl } from ".."
import { IVersionData } from "@/interfaces/versionInterface"

const versionService = {
    async getLatest() {
        return apiUrl.get<IVersionData>('/versions/latest')
            .then(response => {
                return response.data
            })
            .catch((error: any) => error.response)
    },
    async getByNumber(number: string) {
        return apiUrl.get('/version', {
            params: { number }
        })
            .then((response: any) => response)
            .catch((error: any) => error.response)
    }
}

export default versionService