import { dispatchUpdateNiche } from '@/store/niche/nicheStore';

export const getCurrentCustomer = () => localStorage.getItem('customerId');
export const saveLocalCustomer = (customer: string) =>
  localStorage.setItem('customerId', customer);
export const removeLocalCustomer = () => localStorage.removeItem('customerId');

export const getCurrentCustomerName = () =>
  localStorage.getItem('customerName');
export const saveLocalCustomerName = (customerName: string) =>
  localStorage.setItem('customerName', customerName);
export const removeLocalCustomerName = () =>
  localStorage.removeItem('customerName');

export const getCurrentCustomerNiche = () => localStorage.getItem('niche');
export const saveLocalCustomerNiche = (niche: string) =>
  localStorage.setItem('niche', niche);
export const removeLocalCustomerNiche = () => localStorage.removeItem('niche');

export const getCurrentCustomerDisplay = () =>
  localStorage.getItem('customerDisplayId');
export const saveCurrentCustomerDisplay = (customerDisplay: string) =>
  localStorage.setItem('customerDisplayId', customerDisplay);
export const removeCurrentCustomerDisplay = () =>
  localStorage.removeItem('customerDisplayId');

// possui a listagem de ids de projetos do usuário logado
export const getCustomersFromUser = () => localStorage.getItem('customersFromUser') ?? '[]';
export const saveLocalCustomersFromUser = (customersFromUser: string) => localStorage.setItem('customersFromUser', customersFromUser);
export const removeLocalCustomersFromUser = () => localStorage.removeItem('customersFromUser');

// possui a listagem de todos os projetos existentes e ativos, com 
// id, nome, flag se tem identificador de ML de CR e flag se tem identificador de ML de sentimento
export const getCustomersFromLocal = () => localStorage.getItem('customers');
export const saveLocalCustomers = (customers: string) => localStorage.setItem('customers', customers);
export const removeLocalCustomers = () => localStorage.removeItem('customers'); 

export const getCustomerDisplaySelected = () => 
  localStorage.getItem('customerDisplaySelected')
export const saveCustomerDisplaySelected = (customerDisplaySelected: string) =>
  localStorage.setItem('customerDisplaySelected', customerDisplaySelected)
export const removeCustomerDisplaySelected = () =>
  localStorage.removeItem('customerDisplaySelected')