import { ActionContext } from 'vuex';
import { getStoreAccessors } from 'typesafe-vuex';
import { State as RootState } from '@/store/state';
import {
  IChartParams,
  IWordcloud,
  IWordcloudParams,
} from '@/interfaces/dashboardInterface';
import dashboardService from '@/services/dashboard/dashboard';
import { DashboardState } from './dashboardState';

export type DashboardContext = ActionContext<DashboardState, RootState>;

export const dashboard = {
  namespace: true,
  actions: {
    async actionGetWordcloud(
      context: DashboardContext,
      payload: IWordcloudParams
    ): Promise<IWordcloud | any> {
      try {
        const response = await dashboardService.getWordcloud(payload);
        return response;
      } catch (error) {
        return error;
      }
    },
    async actionGetChartContactReason(
      context: DashboardContext,
      payload: IChartParams
    ): Promise<any> {
      try {
        const response = await dashboardService.getChartContactReason(payload);
        return response;
      } catch (error) {
        return error;
      }
    },
    async actionGetChartKpi(
      context: DashboardContext,
      payload: IChartParams
    ): Promise<any> {
      try {
        const response = await dashboardService.getChartKpi(payload);
        return response;
      } catch (error) {
        return error;
      }
    },
  },
};

const { dispatch, read, commit } = getStoreAccessors<DashboardState, RootState>(
  ''
);

const { actions } = dashboard;

export const dispatchGetWordcloud = dispatch(actions.actionGetWordcloud);
export const dispatchGetChartContactReason = dispatch(
  actions.actionGetChartContactReason
);
export const dispatchGetChartKpi = dispatch(actions.actionGetChartKpi);
