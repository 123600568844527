import { apiUrl } from '../index';

export const authService = {
  async login(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return apiUrl
      .post('/auth', params)
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },

  async validateToken() {
    return apiUrl
      .get('/auth/token')
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
};
