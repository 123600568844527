import { TwoWayMap } from '@/utils/mapUtils';

export enum Permission {
  admin = 1,
  calibrator = 2,
  write = 3,
}

export enum PermissionName {
  admin = 'Administrador',
  calibrator = 'Calibrador',
  write = 'Edição',
}

export const PermissionMap = new TwoWayMap({
  [PermissionName.admin]: Permission.admin,
  [PermissionName.calibrator]: Permission.calibrator,
  [PermissionName.write]: Permission.write,
});

export interface IGetUserWithPermission {
  id: number;
  username: string;
  first_name: string;
  role_id: number;
  created_at: string;
  update_at: string;
  imageUrl?: string;
  active?: boolean | false
  image_url: string | undefined 
}

export interface IGetUserById {
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  customers_id: number[];
  role_id: number;
  id: number;
  created_at: string;
  password?: string;
}

export interface IGetUserWithToken {
  id: number;
  permission: Permission;
  username: string;
  access_token: string;
}

export interface IUserForm {
  username: string;
  password: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  customers: number[];
  permission: Permission;
}

export interface IUserSettings {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  username: string;
  password: string;
  image_url?: string;
  customers: number[];
}

export interface IUserData {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  username: string;
  password: string;
  image_url: string;
  customers: number[];
}

export interface ICreateUsers {
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  customers_id: number[];
  password: string;
  permission: Permission;
}

export interface IUpdateUserInfo {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
}

export interface IUpdateUser {
  username: string;
  new_password?: string;
  permission: Permission;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  customers_id: number[];
}

export interface IUpdateUserPass {
  old_password: string;
  new_password: string;
}

export interface IValidateRecoveryToken {
  email: string;
  token: number;
}

export interface IChangePasswordAtLogin {
  username: string
  oldPassword: string
  newPassword: string
}

export interface IDeleteUser {
  id: number;
  disabled: boolean;
}
