import { apiUrl } from '../index';
import { IFeelingAccuracy, IFeelingData } from '@/interfaces/feelingInterface';

const feelingService = {
  async getFeelings(filter: any) {
    let params = {};
    if (filter != null) {
      params = {
        params: {
          start_date: filter.date_start,
          end_date: filter.date_end,
        },
      };
    }
    return apiUrl
      .get('/feelings', params)
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async createFeeling(payload: IFeelingData) {
    return apiUrl
      .post('/feeling', payload)
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async getFeelingAccuracy(payload: IFeelingAccuracy) {
    return apiUrl
      .get('/feeling/info', {
        params: {
          niche_id: payload.niche_id,
          customer_id: payload.customer_id,
          open_date: payload.open_date,
          close_date: payload.close_date,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async updateFeeling(payload: any, customer_id: number, niche_id: number) {
    return apiUrl
      .put(`/feeling/refined`, payload, {
        params: {
          niche_id: niche_id,
          customer_id: customer_id,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  },
  async getFellingCalibrationStatus(payload: any) {
    return apiUrl
      .get('/feeling/status', {
        params: {
          customer_id: payload.customer_id,
          niche_id: payload.niche_id,
          start_date: payload.start_date,
          end_date: payload.end_date,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  },
};

export default feelingService;
