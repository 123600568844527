import { ActionContext } from 'vuex';
import { getStoreAccessors } from 'typesafe-vuex';
import { State as RootState } from '@/store/state';
import {
  IGetChanellData,
  IChanellData,
  IChanellsUpdateData,
  IChanellsUpdateDataWithId,
  IDeleteChanell,
} from '@/interfaces/chanellInterface';
import chanellService from '@/services/chanell/chanellService';
import { ChanellState } from './chanellState';

export type ChanellContext = ActionContext<ChanellState, RootState>;

export const chanell = {
  namespace: true,
  actions: {
    async actionGetChanells(
      context: ChanellContext,
      payload: { disabled: boolean }
    ): Promise<IGetChanellData | any> {
      try {
        const response = await chanellService.getChanell(payload.disabled);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionCreateChanell(
      context: ChanellContext,
      payload: IChanellData
    ): Promise<IChanellData | any> {
      try {
        const response = await chanellService.createChanell(payload);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionDeleteChanell(
      context: ChanellContext,
      payload: IDeleteChanell
    ) {
      try {
        const { id, disabled } = payload;
        const response = await chanellService.deleteChanell(id, disabled);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionGetChanellById(context: ChanellContext, id: number) {
      try {
        const response = await chanellService.getChanellById(id);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionUpdateChanell(
      context: ChanellContext,
      payload: IChanellsUpdateDataWithId
    ) {
      try {
        const updateChanell: IChanellsUpdateData = {
          channel_name: payload.channel_name,
          channel_type: payload.channel_type,
          channel_description: payload.channel_description,
        };
        const response = await chanellService.updateChanell(
          payload.id,
          updateChanell
        );

        return response;
      } catch (error) {
        return error;
      }
    },
  },
};

const { dispatch, read, commit } = getStoreAccessors<ChanellState, RootState>(
  ''
);

const { actions } = chanell;

export const dispatchGetChanells = dispatch(actions.actionGetChanells);
export const dispatchCreateChanell = dispatch(actions.actionCreateChanell);
export const dispatchDeleteChanell = dispatch(actions.actionDeleteChanell);
export const dispatchUpdateChanell = dispatch(actions.actionUpdateChanell);
export const dispatchGetChanellById = dispatch(actions.actionGetChanellById);
