import { AxiosResponse } from 'axios';
import { apiUrl } from '../index';

const recipesManagementService = {
  async getRecipe(customer_id: number) {
    return apiUrl
      .get('/recipe_management', {
        params: {
          customer_id: customer_id,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async updateRecipe(customer_id: number, payload: any) {
    return apiUrl
      .post(`/recipe_management`, payload, {
        params: {
          customer_id: customer_id,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
};

export default recipesManagementService;
