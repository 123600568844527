import { ActionContext } from 'vuex';
import { getStoreAccessors } from 'typesafe-vuex';
import { State as RootState } from '@/store/state';
import {
  IGetContactReasonData,
  IContactReasonsUpdateDataWithId,
  ICreateCategory,
  ICreateContactReason,
  ICreateSubContactReason,
  IContactReasonsResults,
  IContactReasonsCalibration,
  IContactReasonDownload,
} from '@/interfaces/contactReasonInterface';
import contactReasonService from '@/services/contactReason/contactReasonService';
import { ContactReasonState } from './contactReasonState';

export type ContactReasonContext = ActionContext<ContactReasonState, RootState>;

export const contactReason = {
  namespace: true,
  actions: {
    async actionGetContactReason(
      context: ContactReasonContext,
      niche: number
    ): Promise<IGetContactReasonData | any> {
      try {
        const response = await contactReasonService.getContactReason(niche);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionGetCategory(
      context: ContactReasonContext,
      niche: number
    ): Promise<IGetContactReasonData | any> {
      try {
        const response = await contactReasonService.getCategory(niche);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionGetSubContactReason(
      context: ContactReasonContext,
      niche: number
    ): Promise<IGetContactReasonData | any> {
      try {
        const response = await contactReasonService.getSubCategory(niche);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionCreateContactReason(
      context: ContactReasonContext,
      payload: ICreateContactReason
    ): Promise<ICreateContactReason | any> {
      try {
        const response = await contactReasonService.createContactReason(
          payload
        );

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionCreateCategory(
      context: ContactReasonContext,
      payload: ICreateCategory
    ): Promise<ICreateCategory | any> {
      try {
        const response = await contactReasonService.createCategory(payload);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionCreateSubContactReason(
      context: ContactReasonContext,
      payload: ICreateSubContactReason
    ): Promise<ICreateSubContactReason | any> {
      try {
        const response = await contactReasonService.createSubContactReason(
          payload
        );

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionDeleteContactReason(context: ContactReasonContext, id: number) {
      try {
        const response = await contactReasonService.deleteContactReason(id);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionGetContactReasonById(
      context: ContactReasonContext,
      id: number
    ) {
      try {
        const response = await contactReasonService.getContactReasonById(id);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionUpdateContactReason(
      context: ContactReasonContext,
      payload: any
    ) {
      try {
        const response = await contactReasonService.updateContactReason(
          payload.update,
          payload.customer_id,
          payload.niche_id
        );

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionGetContactReasonResults(
      context: ContactReasonContext,
      payload: IContactReasonsResults
    ): Promise<IGetContactReasonData | any> {
      try {
        const response = await contactReasonService.getContactReasonResults(
          payload
        );
        return response;
      } catch (error) {
        return error;
      }
    },
    async actionQueryBuilder(
      context: ContactReasonContext,
      payload: IContactReasonsCalibration
    ): Promise<IGetContactReasonData | any> {
      try {
        const response = await contactReasonService.makeQuery(payload);

        return response;
      } catch (error) {
        return error;
      }
    },
    async actionDownloadCsvCr(
      context: ContactReasonContext,
      payload: IContactReasonDownload
    ): Promise<any> {
      try {
        const response = await contactReasonService.downloadFile(payload);

        return response;
      } catch (error) {
        return error;
      }
    },
  },
};

const { dispatch, read, commit } = getStoreAccessors<
  ContactReasonState,
  RootState
>('');

const { actions } = contactReason;

export const dispatchGetContactReason = dispatch(
  actions.actionGetContactReason
);
export const dispatchCreateContactReason = dispatch(
  actions.actionCreateContactReason
);
export const dispatchDeleteContactReason = dispatch(
  actions.actionDeleteContactReason
);
export const dispatchUpdateContactReason = dispatch(
  actions.actionUpdateContactReason
);
export const dispatchGetContactReasonById = dispatch(
  actions.actionGetContactReasonById
);
export const dispatchGetCategory = dispatch(actions.actionGetCategory);
export const dispatchGetSubContactReason = dispatch(
  actions.actionGetSubContactReason
);
export const dispatchCreateCategory = dispatch(actions.actionCreateCategory);
export const dispatchCreateSubContactReason = dispatch(
  actions.actionCreateSubContactReason
);

export const dispatchGetContactReasonResults = dispatch(
  actions.actionGetContactReasonResults
);

export const dispatchMakeQuery = dispatch(actions.actionQueryBuilder);

export const dispatchDownloadCsvCr = dispatch(actions.actionDownloadCsvCr);
