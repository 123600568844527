import { ActionContext } from 'vuex';
import { getStoreAccessors } from 'typesafe-vuex';
import { State as RootState } from '@/store/state';
import { RecipeManagementState } from './recipesManagementState';
import { IRecipeManagement } from '@/interfaces/recipesManagement/recipesManagementInterface';
import recipesManagementService from '@/services/recipesManagement/recipesManagementService';

export type RecipeContext = ActionContext<RecipeManagementState, RootState>;

export const recipesManagement = {
  namespace: true,
  state: (): RecipeManagementState => ({
    recipe: [] as IRecipeManagement[],
  }),
  getters: {
    recipe: (state: RecipeManagementState) => state.recipe,
  },
  mutations: {
    setRecipe(state: RecipeManagementState, recipe: any) {
      state.recipe = recipe;
    },
  },
  actions: {
    async actionGetRecipe(
      context: RecipeContext,
      customer_id: number
    ): Promise<IRecipeManagement | any> {
      try {
        const response = await recipesManagementService.getRecipe(customer_id);
        commitRecipe(context, {} as any);
        if (response.status === 200) {
          commitRecipe(context, response.data);
          return response;
        }
      } catch (error) {
        return error;
      }
    },
    async actionUpdateRecipe(context: RecipeContext, payload: any) {
      try {
        const response = await recipesManagementService.updateRecipe(
          payload.customer_id,
          payload.recipe
        );

        return response;
      } catch (error) {
        return error;
      }
    },
  },
};

const { dispatch, read, commit } = getStoreAccessors<
  RecipeManagementState,
  RootState
>('');

const { actions } = recipesManagement;

export const dispatchGetRecipe = dispatch(actions.actionGetRecipe);
export const dispatchSaveRecipe = dispatch(actions.actionUpdateRecipe);

const { mutations } = recipesManagement;
export const commitRecipe = commit(mutations.setRecipe);

const { getters } = recipesManagement;
export const readRecipe = read(getters.recipe);
