import { ActionContext } from 'vuex';
import { getStoreAccessors } from 'typesafe-vuex';
import { State as RootState } from '@/store/state';
import { ICreateKpiRecalculationData, IGetKPIsData, IPagedRecalculationData, IRecalculationConfigData, IRecalculationData, IRecalculationListData} from '@/interfaces/kpiInterface';
import kpiService from '@/services/kpi/KpiService';
import { KpiState } from './KpiState';
import { dispatchCreateCategory } from '../contactReason/contactReasonStore';

export type KpiContext = ActionContext<KpiState, RootState>;

export const kpiStore = {
  namespace: true,
  state: (): KpiState => ({
    kpi: [] as IGetKPIsData[],
    recalculations: [] as IRecalculationData[],
    recalculation_config: {} as IRecalculationConfigData
  }),
  getters: {},
  mutations: {},
  actions: {
    async actionGetKpiCalibration(
      context: KpiContext,
      payload: any
    ): Promise<IGetKPIsData | any> {
      try {
        const response = await kpiService.getKpiCalibration(payload);

        return response;
      } catch (error) {
        return error;
      }
    },

    async actionGetKpiCalibrationWordCloud(
      context: KpiContext,
      payload: any
    ): Promise<any> {
      try {
        const response = await kpiService.getWordcloud(payload);

        return response;
      } catch (error) {
        return error;
      }
    },

    // RECALCULATIONS
    async actionGetRecalculations(
      context: KpiContext,
      payload: IPagedRecalculationData
    ): Promise<IRecalculationListData | any> {
      try {
        const response = await kpiService.getRecalculations(payload)
        return response
      } catch(error) {
        return error
      }
    },

    async actionGetRecalculationConfig(
      context: KpiContext,
      payload: number
    ): Promise<IRecalculationConfigData | any> {
      try {
        const response = await kpiService.getRecalculationConfig(payload)
        return response
      } catch (error) {
        return error
      }
    },

    async actionCreateRecalculation(
      context: KpiContext,
      payload: ICreateKpiRecalculationData
    ): Promise<IRecalculationData | any> {
      try {
        const response = await kpiService.createRecalculation(payload)
        return response
      } catch(error) {
        return error
      }
    },

    async actionGetRecalculationRunningStatus(
      context: KpiContext,
      customerId: number 
    ): Promise<boolean | any> {
      try {
        const response = await kpiService.getRecalculationRunningStatus(customerId)
        return response
      }
      catch (error) {
        return error
      }
    },

    async actionGetRecalculationDates(
      context: KpiContext,
      customerId: number
    ): Promise<string[] | any> {
      try {
        const response = await kpiService.getRecalculationDates(customerId)
        return response
      } catch (error) {
        return error
      }
    }
  }
};

const { dispatch, read, commit } = getStoreAccessors<KpiState, RootState>('');
const { actions } = kpiStore;

export const dispatchGetKpiCalibration = dispatch(
  actions.actionGetKpiCalibration
)
export const dispatchGetKpiCalibrationWordCloud = dispatch(
  actions.actionGetKpiCalibrationWordCloud
)
export const dispatchGetRecalculations = dispatch(actions.actionGetRecalculations)
export const dispatchGetRecalculationConfig = dispatch(actions.actionGetRecalculationConfig)
export const dispatchCreateRecalculation = dispatch(actions.actionCreateRecalculation)
export const dispatchGetRecalculationRunningStatus = dispatch(actions.actionGetRecalculationRunningStatus)
export const dispatchGetRecalculationDates = dispatch(actions.actionGetRecalculationDates)

const { mutations } = kpiStore;
const { getters } = kpiStore;
