import { ICreateKpiRecalculationData, IPagedRecalculationData, IRecalculationConfigData } from '@/interfaces/kpiInterface';
import { apiUrl } from '../index';

const kpiService = {
  async getKpiCalibration(filters: any = null) {
    let params = {};
    if (filters != null) {
      params = {
        params: {
          start_date: filters.start_date,
          end_date: filters.end_date,
          customer_id: filters.customer_id,
        },
      };
    }
    return apiUrl
      .get('/kpi/calibration', params)
      .then((response) => response)
      .catch((error) => error.response);
  },
  async getWordcloud(filters: any) {
    return apiUrl
      .get('/kpi/calibration/wordcloud', { params: {...filters}})
      .then((response) => response)
      .catch((error) => error.response);
  },
  /*
  -----------------------------------
  --------- RECALCULATIONS ----------
  -----------------------------------
  */
  async getRecalculations(payload: IPagedRecalculationData) {
    return apiUrl
      .get('/kpi/recalculations', { params: { ...payload}})
      .then(response => response)
      .catch(error => error.response)
  },
  async createRecalculation(payload: ICreateKpiRecalculationData) {
    return apiUrl
      .post('/kpi/recalculations', payload)
      .then(response => response)
      .catch(error => error.response)
  },
  async getRecalculationConfig(payload: number) {
    return apiUrl
      .get('/kpi/recalculations/config', { params: { customer_id: payload }})
      .then(response => response)
      .catch(error => error.response)
  },
  async getRecalculationRunningStatus(customer_id: number) {
    return apiUrl
      .get('/kpi/recalculations/running/status', { params: { customer_id }})
      .then(response => response)
      .catch(error => error.response)
  },
  async getRecalculationDates(customer_id: number) {
    return apiUrl
      .get('/kpi/recalculations/dates', { params: { customer_id }})
      .then(response => response)
      .catch(error => error.response)
  }
};

export default kpiService;
