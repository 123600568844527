import { INicheData, INichesUpdateData } from '@/interfaces/nicheInterface';
import { apiUrl } from '../index';

const nicheService = {
  async getNiche() {
    return apiUrl
      .get('/niches')
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async getNicheById(id: number) {
    return apiUrl
      .get('/niche', {
        params: {
          niche_id: id,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async createNiche(payload: INicheData) {
    return apiUrl
      .post('/niche', payload)
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async updateNiche(id: number, payload: INichesUpdateData) {
    return apiUrl
      .put(`/niche`, payload, {
        params: {
          niche_id: id,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async deleteNiche(id: number) {
    return apiUrl
      .delete(`/niche`, {
        params: {
          niche_id: id,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
};

export default nicheService;
