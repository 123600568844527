export default class RecipesRawingModel {
  public unique_identifier_columns: string[] = [];
  public remove_names: string[] = [];
  public remove_personal_document: string[] = [];
  public remove_telephone_number: string[] = [];
  public remove_address_info: string[] = [];
  public remove_car_info: string[] = [];
  public remove_email: string[] = [];
  public remove_links: string[] = [];
  public hash_columns: string[] = [];
  public export: string[] = [];
}
