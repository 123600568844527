import { RESOURCES_CONSTANT } from '@/utils/constants/implantationConstant';

export default class Resource {
  public ingestion_type?: string;
  public update_firestore_filters?: string[];

  constructor(ingestion_type?: string) {
    this.ingestion_type =
      ingestion_type || RESOURCES_CONSTANT.ingestion_type[0];
  }
}
