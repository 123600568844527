import { apiUrl } from '../index';

const calibrationStatusService = {
  async getCalibrationStatus(payload: any) {
    return apiUrl
      .get('/contact-reason/retraining', {
        params: {
          niche_id: payload.niche_id,
          start_date: payload.start_date,
          end_date: payload.end_date,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  },
};

export default calibrationStatusService;
