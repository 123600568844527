import { apiUrl } from '../index';
import { IContactReasonScaleData } from '@/interfaces/contactReasonScaleInterface';
import { AxiosResponse } from 'axios';

const contactReasonScale = {
  async makeQuery(payload: IContactReasonScaleData): Promise<AxiosResponse> {
    return apiUrl
      .post('/contact-reason/refined', payload, {
        params: {
          page: payload.page,
          items_per_page: payload.limit,
          sortField: payload.sortField,
          sortOrder: payload.sortOrder,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  },
};

export default contactReasonScale;
