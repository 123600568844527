export class TwoWayMap {
  map: { [key: string]: any };
  reverseMap: typeof this.map;

  constructor(map: { [key: string]: any }) {
    this.map = map;
    this.reverseMap = {};
    for (const key in map) {
      const value = map[key];
      this.reverseMap[value] = key;
    }
  }
  get(key: string | number) {
    return this.map[key];
  }
  revGet(key: string | number) {
    return this.reverseMap[key];
  }
  keys() {
    return Object.keys(this.map);
  }
  revKeys() {
    return Object.keys(this.reverseMap);
  }
}
