import { ActionContext } from 'vuex';
import { getStoreAccessors } from 'typesafe-vuex';
import { State as RootState } from '@/store/state';
import { FiltersState } from './filtersState';
import filtersService from '@/services/filters/filtersService';

export type FiltersContext = ActionContext<FiltersState, RootState>;

export const filters = {
  namespace: true,
  getters: {},
  mutations: {},
  actions: {
    async actionGetFilters(
      context: FiltersContext,
      customerId: number | string
    ) {
      const response = await filtersService.getFilters(customerId);
      return response;
    },
  },
};

const { dispatch } = getStoreAccessors<FiltersState, RootState>('');
const { actions } = filters;

export const dispatchGetFilters = dispatch(actions.actionGetFilters);
