import { DATA_INGESTION_CONSTANT } from '@/utils/constants/implantationConstant';

export default class DataIngestion {
  public customer_id = '';
  public niche_id = 0;
  public nicheName?: string;
  public customerDisplayId = 0;
  public customerName = '';
  public chanell = '';
  public operation = '';
  public conn_type = '';
  public host = '';
  public login = '';
  public password = '';
  public port = 8000;
  public sFtpFolder = '';
  public filePrefix = '';
  public fileFormat = '';
  public moveFilesFtp = false;
  public cron = '';
  public reprocessing: number = DATA_INGESTION_CONSTANT.reprocessing;
  public isEtl = false;
  public etlParams = { etl_first: false } as Record<string, string | boolean>;
  public interlocutor = {
    standardize_interlocutor: false,
    column: 'message_author',
    agent: '',
    client: '',
  };
}
