import { apiUrl } from '../index';
import {
  IChartParams,
  IWordcloudParams,
} from '@/interfaces/dashboardInterface';

const dashboardService = {
  async getWordcloud(payload: IWordcloudParams) {
    return apiUrl
      .get('/wordcloud', {
        params: {
          customer: payload.customer,
          start_date: payload.startDate,
          end_date: payload.endDate,
          interface_action: payload.interfaceAction,
          username: payload.username,
          stopwords: payload.stopwords,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async getChartContactReason(payload: IChartParams) {
    return apiUrl
      .get('/dashboard/contact-reason', {
        params: {
          customer: payload.customer,
          start_date: payload.startDate,
          end_date: payload.endDate,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async getChartKpi(payload: IChartParams) {
    return apiUrl
      .get('/dashboard/kpi', {
        params: {
          customer: payload.customer,
          start_date: payload.startDate,
          end_date: payload.endDate,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
};

export default dashboardService;
