import { IMain } from '@/interfaces/implantation/mainInterface';
import { apiAutomationClientUrl, apiUrl } from '../index';

const implantationService = {
  async createMain(payload: IMain) {
    return apiAutomationClientUrl
      .post('/main', payload)
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async saveImplantation(payload: any) {
    return apiUrl
      .post('/implantation', payload)
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
  async getImplantation(customerId: string) {
    return apiUrl
      .get('/implantation', {
        params: {
          customer_id: customerId,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error.response);
  },
};

export default implantationService;
