import { ActionContext } from 'vuex';
import { getStoreAccessors } from 'typesafe-vuex';
import { State as RootState } from '@/store/state';
import {
  IGetUserWithPermission,
  ICreateUsers,
  IUpdateUser,
  IUpdateUserPass,
  IUpdateUserInfo,
  IDeleteUser,
} from '@/interfaces/usersInterface';
import usersService from '@/services/users/usersService';
import { UsersState } from './usersState';
import { AxiosResponse } from 'axios';

export type UsersContext = ActionContext<UsersState, RootState>;

export const users = {
  namespace: true,
  getters: {
    usersWithPermissions: (state: UsersState) => state.usersWithPermissions,
    usersList: (state: UsersState) => state.usersList,
  },
  mutations: {
    setusersWithPermissions(
      state: UsersState,
      payload: IGetUserWithPermission
    ) {
      state.usersWithPermissions = payload;
    },
    setUsersList(state: UsersState, payload: Array<IGetUserWithPermission>) {
      state.usersList = payload
    },
  },
  actions: {
    async actionGetUsers(
      context: UsersContext,
      payload: { disabled: boolean }
    ) {
      const usersList = []
      const response = await usersService.getUsers(payload.disabled);

      for (const user of response.data) {
        usersList.push({
          ...user,
          active: !payload.disabled
        })
      }

      commitUsersList(context, usersList)
      return response;
    },
    async actionGetUserAvatar(context: UsersContext, id: number) {
      const response = await usersService.getUserAvatar(id);
      return response;
    },
    async actionGetUserById(context: UsersContext, id: number) {
      const response = await usersService.getUserById(id);
      return response;
    },
    async actionCreateUsers(context: UsersContext, payload: ICreateUsers) {
      const response = await usersService.createUsers(payload);
      return response;
    },
    async actionDeleteUser(context: UsersContext, payload: IDeleteUser) {
      const { id, disabled } = payload;
      const response = await usersService.deleteUser(id, disabled);
      return response;
    },
    async actionUpdateUserInfo(
      context: UsersContext,
      payload: { id: number } & IUpdateUserInfo
    ) {
      const userInfo = (({ id, ...rest }) => rest)(payload);
      const response = await usersService.updateUserInfo(payload.id, userInfo);
      return response;
    },
    async actionUpdateUserAvatar(
      context: UsersContext,
      payload: { id: number; file: File }
    ) {
      const response = await usersService.updateAvatar(
        payload.id,
        payload.file
      );
      return response;
    },
    async actionUpdateUser(
      context: UsersContext,
      payload: { id: number } & IUpdateUser
    ) {
      const userInfo = (({ id, ...rest }) => rest)(payload);
      const response = await usersService.updateUser(payload.id, userInfo);
      return response;
    },
    async actionUpdateUserPass(
      context: UsersContext,
      payload: IUpdateUserPass
    ): Promise<AxiosResponse> {
      const response = await usersService.updateUserPass(payload);
      return response;
    },
  },
};

const { dispatch, read, commit } = getStoreAccessors<UsersState, RootState>('');

const { actions } = users;

export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchCreateUsers = dispatch(actions.actionCreateUsers);
export const dispatchDeleteUser = dispatch(actions.actionDeleteUser);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);
export const dispatchUpdateUserAvatar = dispatch(
  actions.actionUpdateUserAvatar
);
export const dispatchGetUserAvatar = dispatch(actions.actionGetUserAvatar);
export const dispatchUpdateUserInfo = dispatch(actions.actionUpdateUserInfo);
export const dispatchUpdateUserPass = dispatch(actions.actionUpdateUserPass);
export const dispatchGetUserById = dispatch(actions.actionGetUserById);

const { mutations } = users;

export const commitUsersWithPermissions = commit(mutations.setusersWithPermissions);
export const commitUsersList = commit(mutations.setUsersList);

const { getters } = users;

export const readtUsersWithPermissions = read(getters.usersWithPermissions);
export const readUsersList = read(getters.usersList);